import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClauseCategory, AvailableClauses } from "../models/analysisData";
import { AnalysisPayload } from "../models/analysisData";

interface AnalysisState {
  riskData: ClauseCategory[];
  availableClauses: AvailableClauses;
  isLoading: boolean;
  activeData: AnalysisPayload;
  analysisPayload: AnalysisPayload;
  lastAnalysisTimestamp: number | null;
  selectedContractType: string;
  selectedPlaybookId: string;
  analysisResults: any;
}

const initialState: AnalysisState = {
  riskData: [],
  availableClauses: { riskClauses: [], missingClauses: [], favClauses: [] },
  isLoading: false,
  activeData: {
    userId: null,
    playbookId: null,
    fileId: null,
  },
  analysisPayload: {
    userId: null,
    playbookId: null,
    fileId: null,
  },
  lastAnalysisTimestamp: null,
  selectedContractType: "master_service",
  selectedPlaybookId: "",
  analysisResults: null,
};

const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    setRedlineHeader: (state, action: PayloadAction<ClauseCategory[]>) => {
      state.riskData = action.payload;
      state.lastAnalysisTimestamp = Date.now();
    },
    setCategorywiseClauses: (state, action: PayloadAction<AvailableClauses>) => {
      state.availableClauses = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setActiveData: (
      state,
      action: PayloadAction<{
        playbookId: string | null;
        fileId: string | null;
      }>
    ) => {
      const { playbookId, fileId } = action.payload;
      state.activeData = { ...state.activeData, playbookId, fileId };
    },
    setAnalysisPayload: (state, action: PayloadAction<Partial<AnalysisPayload>>) => {
      state.analysisPayload = {
        ...state.analysisPayload,
        ...action.payload,
      };
    },
    clearAnalysisData: (state) => {
      state.riskData = [];
      state.availableClauses = { riskClauses: [], missingClauses: [], favClauses: [] };
      state.lastAnalysisTimestamp = null;
    },
    // Added new reducers for contract type and playbook ID
    setSelectedContractType: (state, action: PayloadAction<string>) => {
      state.selectedContractType = action.payload;
    },
    setSelectedPlaybookId: (state, action: PayloadAction<string>) => {
      state.selectedPlaybookId = action.payload;
    },
    setAnalysisResults(state, action: PayloadAction<any>) {
      state.analysisResults = action.payload; // Store analysis results
      state.lastAnalysisTimestamp = Date.now();
    },
  },
});

export const {
  setRedlineHeader,
  setCategorywiseClauses,
  setIsLoading,
  setActiveData,
  setAnalysisPayload,
  clearAnalysisData,
  setSelectedContractType,
  setSelectedPlaybookId,
  setAnalysisResults,
} = analysisSlice.actions;

export default analysisSlice.reducer;
