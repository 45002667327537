/* eslint-disable no-undef */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SidebarMain from "../components/SidebarMain";
import backIcon from "../../../assets/back.png";
import infoIcon from "../../../assets/infoballoon.png";
import likeIcon from "../../../assets/like.png";
import dislikeIcon from "../../../assets/dislike.png";

const EditClausePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clause } = location.state || {};
  const [strikedText, setstrikedText] = useState(clause?.location || "");
  const [updatedContext, setUpdatedContext] = useState(clause?.updatedContext || "");
  const [addedComment, setAddedComment] = useState(
    "test comment" // Default comment if not provided
  );
  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <SidebarMain />
      <div
        style={{
          flexGrow: 1,
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          backgroundColor: "white",
        }}
      >
        {/* Back Button */}
        <div
          style={{ display: "flex", alignItems: "center", gap: "8px", cursor: "pointer" }}
          onClick={() => navigate("/analyze")}
        >
          <img src={backIcon} style={{ width: "24px", height: "24px" }} />
          <span style={{ fontSize: "14px", fontStyle: "normal", fontWeight: "400", color: "#424165" }}>Back</span>
        </div>
        {/* KEY TERM HEADING */}
        <div style={{ display: "flex", alignItems: "center", gap: "60px", cursor: "pointer" }}>
          <h1 style={{ fontSize: "16px", fontStyle: "normal", fontWeight: "600", color: "#08081A" }}>{clause.key}</h1>
          {/* <button
            style={{
              padding: "3px 5px",
              backgroundColor: "#F5F5F5",
              color: "#3E51EE",
              border: "1px solid #3E51EE",
              borderRadius: "8px",
              cursor: "pointer",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            View AI Reasoning
          </button> */}
        </div>
        {/* Added Text Section */}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "0px",
            }}
          >
            <h3 style={{ fontSize: "14px", fontStyle: "normal", fontWeight: "500", color: "#0A0A22" }}>Added Text</h3>
            <img src={infoIcon} alt="Info" style={{ width: "16px", height: "16px", marginTop: "-7px" }} />
          </div>
          <textarea
            value={updatedContext}
            onChange={(e) => setUpdatedContext(e.target.value)}
            rows={4}
            style={{
              width: "100%",
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#f5f5f5",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              color: "#424165",
              // border: "1px solid #ccc",
              resize: "vertical",
              overflow: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          />
        </div>

        {/* Strike Out Text Section */}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "0px",
            }}
          >
            <h3 style={{ fontSize: "14px", fontStyle: "normal", fontWeight: "500", color: "#0A0A22" }}>
              {" "}
              Strike Out Text
            </h3>
            <img src={infoIcon} alt="Info" style={{ width: "16px", height: "16px", marginTop: "-7px" }} />
          </div>
          <textarea
            value={strikedText}
            onChange={(e) => setstrikedText(e.target.value)}
            rows={4}
            style={{
              width: "100%",
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#f5f5f5",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              color: "#424165",
              // border: "1px solid #ccc",
              resize: "vertical",
              overflow: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          />
        </div>

        {/* Added Comment Section */}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "0px",
            }}
          >
            <h3 style={{ fontSize: "14px", fontStyle: "normal", fontWeight: "500", color: "#0A0A22" }}>
              Added Comment
            </h3>
            <img src={infoIcon} alt="Info" style={{ width: "16px", height: "16px", marginTop: "-7px" }} />
          </div>
          <textarea
            value={addedComment}
            onChange={(e) => setAddedComment(e.target.value)}
            rows={4}
            style={{
              width: "100%",
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#f5f5f5",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              color: "#424165",
              // border: "1px solid #ccc",
              resize: "vertical",
              overflow: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          />
        </div>

        {/* Redline Preview Section */}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "0px",
            }}
          >
            <h3 style={{ fontSize: "14px", fontStyle: "normal", fontWeight: "500", color: "#0A0A22" }}>
              Redline Preview
            </h3>
          </div>
          <div
            style={{
              height: "100px",
              borderRadius: "4px",
              backgroundColor: "#f5f5f5",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ fontSize: "14px", fontStyle: "normal", fontWeight: "400", color: "#424165" }}>
              {" "}
              Preview of the redline...
            </p>
          </div>
        </div>

        {/* Save and Feedback Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <button
            style={{
              padding: "10px 20px",
              backgroundColor: "#3E51EE",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            Save
          </button>
          <div style={{ display: "flex", gap: "8px" }}>
            <img src={likeIcon} alt="" style={{ width: "24px", height: "24px" }} />
            <img src={dislikeIcon} alt="" style={{ width: "24px", height: "24px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClausePage;
