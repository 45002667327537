import * as React from "react";
import { MemoryRouter as Router, Routes, Route } from "react-router-dom";
import SettingsPage from "./pages/SettingsPage";
import GeneratePlaybook from "./pages/GeneratePlaybook";
import AnalyzePage from "./pages/AnalyzePage";
import HomePage from "./pages/HomePage";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/generate" element={<GeneratePlaybook />} />
        <Route path="/analyze" element={<AnalyzePage />} />
        <Route path="/settings" element={<SettingsPage />} />
      </Routes>
    </Router>
  );
};

export default App;
