/* global Office */
import * as React from "react";

interface OfficeWrapperProps {
  children: React.ReactNode;
}

interface OfficeWrapperState {
  isOfficeInitialized: boolean;
}

export class OfficeWrapper extends React.Component<OfficeWrapperProps, OfficeWrapperState> {
  constructor(props: OfficeWrapperProps) {
    super(props);
    this.state = {
      isOfficeInitialized: false,
    };
  }

  componentDidMount() {
    Office.onReady(() => {
      this.setState({ isOfficeInitialized: true });
    });
  }

  render() {
    if (!this.state.isOfficeInitialized) {
      return <div>Loading Office.js...</div>;
    }

    return this.props.children;
  }
}
