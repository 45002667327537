/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { useLocation, useNavigate } from "react-router-dom";
import { searchAndScroll, appendToDocument } from "../../services/document-service";
import {
  addCommentsToDoc,
  strikeOutAndReplaceInDoc,
  scrollToTop,
  addText,
  replaceText,
  removeText,
} from "../../commands/commands";
import aiIcon from "../../../assets/AIicons.png";
import uparrowIcon from "../../../assets/upArrow.png";
import downarrowIcon from "../../../assets/downArrow.png";
import crossIcon from "../../../assets/crossicon.png";

type Clause = {
  category: string;
  key: string;
  updatedContext: string;
  location: string;
  standardAnswer?: string;
  comment?: string;
  analysis?: string;
  action?: string;
};

type AnalysisClauseboxProps = {
  clauses: Clause[];
  type: "risks" | "missing";
  onAccept?: (type: "risks" | "missing", clause: Clause, index: number) => void;
  onReject?: (type: "risks" | "missing", clause: Clause, index: number) => void;
  isAutoRedline: boolean;
};

const AnalysisClausebox: React.FC<AnalysisClauseboxProps> = ({
  clauses = [],
  type,
  onAccept,
  onReject,
  isAutoRedline,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  const [localClauses, setLocalClauses] = useState<{ risks: Clause[]; missing: Clause[]; favourable: Clause[] }>({
    risks: [],
    missing: [],
    favourable: [],
  });
  const [openClauses, setOpenClauses] = useState<{ [key: string]: number | null }>({});
  const isRedliningEnabled = useSelector((state: RootState) => state.toggle.isRedliningEnabled);

  // Update local clauses when props change
  useEffect(() => {
    setLocalClauses((prev) => ({
      ...prev,
      [type]: clauses,
    }));
  }, [clauses, type]);

  useEffect(() => {
    return () => {
      scrollToTop();
    };
  }, [type]);

  const toggleClause = (index: number) => {
    setOpenClauses((prev) => ({
      ...prev,
      [type]: prev[type] === index ? null : index,
    }));
  };

  // Function to locate the clause in the document
  const locateKeyterm = (clause: Clause) => {
    searchAndScroll(clause.location);
  };

  const acceptRedline = async (index: number) => {
    const clauseToAccept = localClauses[type][index];
    console.log(clauseToAccept,'this is that');

    try {
      if (type === "risks") {
        // console.log(clauseToAccept);
        if (clauseToAccept.action === "add") {
          await addText(clauseToAccept.location, clauseToAccept.updatedContext || "");
        } else if (clauseToAccept.action === "replace") {
          await replaceText(clauseToAccept.location, clauseToAccept.updatedContext || "");
        } else if (clauseToAccept.action === "remove") {
          await removeText(clauseToAccept.location || "");
        }
        // await strikeOutAndReplaceInDoc(clauseToAccept.location, clauseToAccept.updatedContext || "");
        if (clauseToAccept.comment && clauseToAccept.comment.trim().length > 0) {
          // console.log("call before addCommentsToDoc");
          await addCommentsToDoc(clauseToAccept.location, clauseToAccept.comment);
          // console.log("call after addCommentsToDoc");
        }
      } else if (type === "missing") {
        const cleanKey = clauseToAccept.key.replace(/^Clause-[\d.]+/, "").trim();
        const commentText = `This is our (${cleanKey}) clause.`;
        await appendToDocument({
          heading: cleanKey,
          description: clauseToAccept.standardAnswer || "",
          headingFontSize: 14,
          descriptionFontSize: 11,
          newPage: true,
        });
        await searchAndScroll(clauseToAccept.standardAnswer || "");
        if (clauseToAccept.standardAnswer && clauseToAccept.standardAnswer.trim().length > 0) {
          await addCommentsToDoc(clauseToAccept.standardAnswer, commentText);
        }
      }
      // else if (type === "favourable") {
      //   // For favourable clauses, append the analysis as a new entry in the document
      //   const heading = clauseToAccept.key || "Favourable Clause";
      //   const description = clauseToAccept.analysis || "This clause is favourable and accepted.";
      //   await appendToDocument({
      //     heading: heading,
      //     description: description,
      //     headingFontSize: 14,
      //     descriptionFontSize: 11,
      //     newPage: true,
      //   });

      //   // Add a comment to indicate acceptance
      //   const commentText = `Favourable clause accepted: ${clauseToAccept.key}`;
      //   if (clauseToAccept.location && clauseToAccept.location.trim().length > 0) {
      //     await addCommentsToDoc(clauseToAccept.location, commentText);
      //   } else {
      //     await addCommentsToDoc("", commentText);
      //   }
      //   await searchAndScroll(description);
      // }

      // Scroll to the updated content in the document
      await locateKeyterm(clauseToAccept);

      // Update local clauses after acceptance
      setLocalClauses((prev) => ({
        ...prev,
        [type]: prev[type].filter((_, i) => i !== index),
      }));

      if (openClauses[type] === index) {
        setOpenClauses((prev) => ({
          ...prev,
          [type]: null,
        }));
      }

      // Notify parent
      onAccept?.(type, clauseToAccept, index);
    } catch (error) {
      console.error("Error while accepting clause:", error);
    }
  };

  const rejectRedline = (index: number) => {
    const clauseToReject = localClauses[type][index];
    setLocalClauses((prev) => ({
      ...prev,
      [type]: prev[type].filter((_, i) => i !== index),
    }));

    if (openClauses[type] === index) {
      setOpenClauses((prev) => ({
        ...prev,
        [type]: null,
      }));
    }
    // Notify parent
    onReject?.(type, clauseToReject, index);
  };

  // Get current tab's clauses
  const currentClauses = localClauses[type];

  const cleanClauseText = (text: string) => {
    return text.replace(/ \(\d+\)$/, "");
  };

  return (
    <div style={{ flex: 1, overflowY: "auto", padding: "0.75rem", marginTop: "-0.5rem" }}>
      {currentClauses.length > 0 ? (
        <div style={{ marginBottom: "1rem" }}>
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                backgroundColor: type === "risks" ? "#FEF2F2" : "#FEFCE8",
                padding: "10px",
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                height: "24px",
                marginBottom: "8px",
              }}
            >
              <h3
                style={{
                  color: type === "risks" ? "#EF4444" : type === "missing" ? "#A16207" : "#22C55E",
                  margin: "-5px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                {/* {currentClauses.length} {type === "risks" ? "Active Risks" : "Missing Clauses"} */}
                {currentClauses.length}{" "}
                {type === "risks" ? "Active Risks" : type === "missing" ? "Missing Clauses" : "Favourable"}
              </h3>
            </div>
          </div>
          {currentClauses.map((clause, index) => (
            <div key={index} style={{ marginBottom: "0.5rem" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  fontStyle: "normal",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#08081A",
                  height: "44px",
                  paddingBottom: "-0.5rem",
                  backgroundColor: openClauses[type] === index ? "#f5f5f5" : "transparent",
                  borderBottom: openClauses[type] === index ? "none" : "1px solid #ccc",
                }}
                onClick={() => {
                  toggleClause(index);
                  locateKeyterm(clause);
                }}
              >
                <span style={{ fontStyle: "normal", fontSize: "12px", fontWeight: "500", padding: "0.5rem" }}>
                  {cleanClauseText(clause.key)}
                </span>
                <img
                  src={openClauses[type] === index ? uparrowIcon : downarrowIcon}
                  alt={openClauses[type] === index ? "Up Arrow" : "Down Arrow"}
                  style={{ width: "24px", height: "24px", marginRight: "12px" }}
                />
              </div>
              {openClauses[type] === index && (
                <div
                  style={{
                    border: type === "risks" ? "1px solid #EF4444" : "1px solid #FDE047",
                    padding: "0.5rem",
                    borderRadius: "8px",
                    marginTop: "0.5rem",
                  }}
                >
                  {type === "risks" && (
                    <p
                      style={{
                        margin: 0,
                        fontSize: "12px",
                        fontWeight: "400",
                        fontStyle: "normal",
                        lineHeight: "1.5",
                        textAlign: "left",
                        color: "#EF4444",
                        // textDecoration: "line-through",
                      }}
                    >
                      {clause.location}
                    </p>
                  )}
                  <p
                    style={{
                      margin: 0,
                      fontSize: "12px",
                      fontWeight: "400",
                      fontStyle: "normal",
                      lineHeight: "1.5",
                      textAlign: "left",
                      color: "#008000",
                    }}
                  >
                    {clause.updatedContext}
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    {isAutoRedline ? (
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#4A88EE",
                          fontWeight: "500",
                        }}
                      >
                        Auto Redline Accepted
                      </p>
                    ) : (
                      <button
                        style={{
                          padding: "0.10rem 0.5rem",
                          backgroundColor: "#ffffff",
                          border: "1px solid #4A88EE",
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontWeight: "500",
                          cursor: "pointer",
                          color: "#4A88EE",
                        }}
                        onClick={() => acceptRedline(index)}
                      >
                        Accept
                      </button>
                    )}

                    <div
                      style={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        visibility: isRedliningEnabled ? "hidden" : "visible",
                      }}
                      onClick={() => rejectRedline(index)}
                    >
                      <img
                        src={crossIcon}
                        alt="Cross Icon"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </div>

                    {type === "risks" && (
                      <img
                        src={aiIcon}
                        alt="Reasoning Icon"
                        style={{
                          width: "28px",
                          height: "28px",
                          cursor: "pointer",
                          marginLeft: "8rem",
                        }}
                        onClick={() => navigate("/reasoning", { state: { clause } })}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "black",
            fontWeight: "bold",
            fontSize: "16px",
            marginLeft: "5px",
          }}
        >
          {type === "risks" ? "No risks detected in this contract" : "No missing term detected in this contract"}
          {/* {type === "risks"
            ? "No risks detected in this contract"
            : type === "missing"
              ? "No missing term detected in this contract"
              : "No favourable clauses detected in this contract"} */}
        </div>
      )}
    </div>
  );
};

export default AnalysisClausebox;
