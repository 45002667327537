/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Layout } from "../components/Layout";
import { BackButton } from "../components/BackButton";
import { getDocumentMetadata, getPageData, searchAndScroll } from "../../services/document-service";
import { analyzePlaybook, uploadContentAsPdf } from "../../services/upload-to-backend";
import { addCommentsToDoc, processLocationData } from "../../commands/commands";

const AnalyzePage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [analysisData, setAnalysisData] = useState<any[]>([]); // Use state for analysisData
  let loaded = false;

  useEffect(() => {
    if (!loaded) {
      loaded = true;
      analyzeContract();
    }
  }, []);

  const analyzeContract = async () => {
    try {
      console.log("I AM HERE");
      setIsLoading(true);

      // Getting name of the current open file.
      const fileName = await getDocumentMetadata();

      // Getting all the content from the file.
      const textContent = await getPageData();

      // Calling the upload function to convert the text to PDF and upload it to the backend.
      const convertAndUploadResponse = await uploadContentAsPdf(textContent, fileName);

      // Calling the playbook analysis function
      const playbookAnalysis = await analyzePlaybook(convertAndUploadResponse);

      // Process the playbook analysis to get the location data
      const processedData = await processLocationData(playbookAnalysis.locationMap);

      // Adding comments to the doc
      processedData.forEach(async (item) => {
        // Calling the comment function to add actual comments on the document.
        await addCommentsToDoc(item.location, item.updatedContext);
      });
      // Update the state with the processed data
      setAnalysisData(processedData);

      setIsLoading(false);
    } catch (error) {
      console.error("Error loading playbooks:", error);
      setIsLoading(false);
    }
  };

  const handleDataItemClick = (dataItem: any) => {
    console.log(dataItem.location);
    searchAndScroll(dataItem.location);
  };

  if (isLoading) {
    return (
      <div style={{ height: "100vh", textAlign: "center" }} className="w-100 d-flex align-items-center">
        <div>
          <img className="w-25 mb-4" src="https://i.gifer.com/VAyR.gif" alt="Loading" />
          <h2>Analysis in progress. Please wait! This can take upto 4-5 minutes.</h2>
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <div className="container">
        <BackButton />
        <h2>LegalGraph AI</h2>
        <br />
      </div>

      <div className="accordion" id="accordionExample">
        {analysisData.map((dataItem, index) => (
          <div key={index} className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <div
                className="accordion-button"
                data-bs-toggle="collapse"
                data-bs-target={`#${index.toString()}`}
                aria-expanded="false"
                aria-controls={index.toString()}
              >
                <div
                  className="d-flex w-100 p-1 justify-content-between align-items-center collapsed"
                  onClick={(e: any) => {
                    console.log(e);
                    if (e.target.localName == "span") {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                >
                  {dataItem.key}
                  <span
                    role="button"
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleDataItemClick(dataItem);
                    }}
                  >
                    Locate
                  </span>
                </div>
              </div>
            </h2>
            <div
              id={index.toString()}
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">{dataItem.analysis}</div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default AnalyzePage;
