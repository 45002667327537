/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Footer from "../components/Footer";
import { setUsergeneratedPlaybook } from "../../store/userDataSlice";
import { switchPlaybook } from "../../services/playbook-service";
import { generatePlaybookFromDocument } from "../../commands/commands";
import { MessageBar, MessageBarType } from "@fluentui/react";
import ToggleExample from "./Toggle";
import Dropdown from "./Dropdown";
import cloudIcon from "../../../assets/cloud.png";
import fileIcon from "../../../assets/file.png";
import tickIcon from "../../../assets/tick.png";
import trashIcon from "../../../assets/trashCan.png";
import eyeOpenIcon from "../../../assets/blueeye_open.png";
import eyeClosedIcon from "../../../assets/blueeye_closed.png";
import sampleFileIcon from "../../../assets/samplecsv.png";
import { setSelectedPlaybookId, setAnalysisPayload } from "../../store/analysisSlice";
import * as dotenv from "dotenv";
dotenv.config();
import axios from "axios";
import toast from "react-hot-toast";

const apiUrl = process.env.API_URL;

const UploadPlaybook: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [File, setFile] = useState<File | null>(null);
  const [isCsvFile, setIsCsvFile] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [playbookData, setPlaybookData] = useState<any[]>([]); //to store all playbook data
  const [playbookName, setPlaybookName] = useState<string>(""); //to store playbookname
  const [PlaybookId, setPlaybookId] = useState<string | null>(null); //to store active playbook id
  const [userId, setUserId] = useState<string | null>(null); //to store the active userid
  const [selectedContractType, setSelectedContractType] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [buttonClickedWhileDisabled, setButtonClickedWhileDisabled] = useState(false);
  const [isFileNameExpanded, setIsFileNameExpanded] = useState(false);
  const fileNameRef = useRef<HTMLSpanElement>(null);
  const [uploadBoxBgColor, setUploadBoxBgColor] = useState<string>("#F9FAFB");

  const storedUserId = localStorage.getItem("activeUserId");
  const storedPlaybookId = localStorage.getItem("activePlaybookId");
  const storedPlaybookName = localStorage.getItem("activePlaybookName");
  // useEffect(() => {
  //   if (storedPlaybookId && storedUserId) {
  //     setPlaybookId(storedPlaybookId);
  //     setUserId(storedUserId);
  //     setPlaybookName(storedPlaybookName);
  //     fetchPlaybookTerms(storedUserId, storedPlaybookId);
  //   }
  // }, []);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0].type == "text/csv") {
      setIsCsvFile(true);
      setFileName(event.target.files[0].name);
    }
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setError(null); // Clear any previous errors
      const allowedTypes = [
        "text/csv",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      const maxFileSize = 2 * 1024 * 1024;

      // Validate file type
      if (!allowedTypes.includes(event.target.files[0].type)) {
        setError("The file format is not supported. Please upload a PDF, DOCX, or CSV file.");
        setUploadBoxBgColor("#FEF2F2");
        setFile(null); // Ensure no invalid file is set
        // setTimeout(() => setError(null), 3000);
        return;
      }

      // Validate file size
      if (event.target.files[0].size > maxFileSize) {
        setError("File size exceeds the 2 MB limit.");
        setFile(null); // Ensure no invalid file is set
        // setTimeout(() => setError(null), 3000);
        return;
      }

      // Set file information immediately

      setFile(file);

      setFileName(file.name);

      setIsCsvFile(file.type === "text/csv");

      setError(null);
      // setFile(event.target.files[0]); // If valid, set the file for processing
    }
  };

  const handleUploadButtonClick = () => {
    if (!selectedContractType) {
      setButtonClickedWhileDisabled(true);
      return;
    }
    setLoading(true);
    generatePlaybook();
  };

  useEffect(() => {
    if (File && File.type !== "text/csv") {
      setLoading(true);

      generatePlaybook();
    }
  }, [File]);
  console.log("csv file seleted or not", isCsvFile);

  useEffect(() => {
    const interval = setInterval(() => {
      setUploadProgress((prev) => (prev < 100 ? prev + 10 : prev));
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const generatePlaybook = async () => {
    setUploadProgress(0); // Reset progress bar
    setIsCsvFile(false);

    const formData = new FormData();
    formData.append("playbook_name", File.name);
    formData.append("csv", File);
    formData.append("user_id", storedUserId);
    formData.append("playbook_type", selectedContractType);
    // console.log("___", formData.get("playbookType"));
    // console.log("generte fn got selcted value:", selectedContractType);

    try {
      // Splitting file format from the string.
      const fileNameParts = File.name.split(".");
      const fileFormat = fileNameParts.length > 1 ? `.${fileNameParts[fileNameParts.length - 1]}` : "";
      let playbookID;
      //for the csv section of files
      if (fileFormat === ".csv") {
        const response = await axios.post(apiUrl + "/api/v1/playbook/generate-playbook", formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        const responseData = response.data;
        console.log("CSV PLAYBOOK ID RESPONSE", response);
        if (responseData.success) {
          playbookID = responseData.data.playbook_id;
          setPlaybookId(responseData.data.playbook_id);
          setPlaybookName(responseData.data.playbook_name);
          setSelectedContractType(responseData.data.contract_type.name);
          localStorage.setItem("activePlaybookName", responseData.data.playbook_name);
          // Dispatch the playbook details to the Redux store
          dispatch(
            setUsergeneratedPlaybook({
              playbookname: responseData.data.playbook_name,
              playbookId: responseData.data.playbook_id,
              active: true,
            })
          );
          // Update Redux store with the new playbook ID

          dispatch(setSelectedPlaybookId(playbookID));

          dispatch(
            setAnalysisPayload({
              userId: storedUserId,

              playbookId: playbookID,

              fileId: null,
            })
          );
        } else {
          toast.error("File Already Exist, Please Upload a new file or rename the existing file");
          navigate("/manageplaybook");
          // setError(
          //   "A playbook with the same name or data already exists. Please rename your file or modify its content before uploading"
          // );
          // setUploadBoxBgColor("#FEFCE8");
          return;
          // setTimeout(() => setError(null), 5000);
        }
      } else {
        // for non-CSV files
        const mockUploadProgress = setInterval(() => {
          setUploadProgress((prev) => {
            if (prev >= 100) {
              clearInterval(mockUploadProgress);
              return 100;
            }
            return prev + 10;
          });
        }, 200);

        await generatePlaybookFromDocument(File, fileFormat)
          .then((result) => {
            clearInterval(mockUploadProgress); // Clear mock progress
            setUploadProgress(100);
            if (result.success) {
              console.log("NON CSV PLAYBOOK ID RESPONSE", result);
              playbookID = result.data.playbook_id;
              setPlaybookId(result.data.playbook_id);
              setPlaybookName(result.data.playbook_name);
              setSelectedContractType(result.data.contract_type.name);
              localStorage.setItem("activePlaybookName", result.data.playbook_name);
              // Dispatch the playbook details to the Redux store
              dispatch(
                setUsergeneratedPlaybook({
                  playbookname: result.data.playbook_name,
                  playbookId: result.data.playbook_id,
                  active: true,
                })
              );
            } else {
              setError(result.error);
              setTimeout(() => setError(null), 5000);
            }
          })
          .catch((error) => {
            clearInterval(mockUploadProgress); // Clear mock progress on error
            setError(error.message);
            setTimeout(() => setError(null), 5000);
          });
      }
      if (playbookID && storedUserId) {
        localStorage.setItem("activePlaybookId", playbookID);
        await switchPlaybook(storedUserId, playbookID); // Ensure the new playbook is active
        await fetchPlaybookTerms(storedUserId, playbookID);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message || "An error occurred during upload");

      setTimeout(() => setError(null), 5000);
    }
  };

  const fetchPlaybookTerms = async (storedUserId, playbookID) => {
    try {
      const getUserPlaybooksResponse = await axios.get(apiUrl + "/api/v1/add-in/get-user-playbooks", {
        params: {
          userId: storedUserId,
          playbookId: playbookID,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      const userPlaybooks = getUserPlaybooksResponse.data.playbookKeys;
      setPlaybookData(userPlaybooks);
    } catch (error) {
      // console.error("Error fetching user playbooks:", error);
    }
  };

  const resetChoice = () => {
    setError(null);
  };

  //clear of data from frontend , not from backend.
  const handleClearPlaybook = () => {
    setPlaybookData([]);
    setPlaybookId(null);
    setFile(null);
    setFileName(null);
    localStorage.removeItem("activePlaybookId");
    localStorage.removeItem("activePlaybookName");
  };

  //before proceeding to analyse page checking word has a current opened file, if not setting an errormessage to user
  const checkDocumentContent = async () => {
    if (Office.context.document) {
      await Word.run(async (context) => {
        const docBody = context.document.body;
        docBody.load("text");
        await context.sync();
        if (docBody.text.length > 0) {
          navigate("/analyze");
        } else {
          setError("Please open a standard contract in the Word Application before proceeding.");
        }
      });
    } else {
      setError("Please ensure the Word Add-In is loaded and the document is accessible.");
    }
  };

  const triggerRiskAnalysis = async () => {
    try {
      if (!storedUserId) return;
      const response = await axios.get(apiUrl + "/api/v1/add-in/get-user-playbooks", {
        params: { userId: storedUserId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      const activePlaybook = response.data.userPlaybooks.find((playbook) => playbook.active === true)?.playbookId;

      if (activePlaybook && activePlaybook === storedPlaybookId) {
        await checkDocumentContent();
      } else {
        // If active playbook is different, switch to the correct playbook
        const switchResponse = await switchPlaybook(storedUserId, storedPlaybookId);
        if (switchResponse) {
          await checkDocumentContent();
        } else {
          setError("Failed to switch playbook.");
        }
      }
    } catch (error) {
      alert("An error occurred while verifying the document state.");
    }
  };

  const cleanClauseText = (text: string) => {
    return text.replace(/ \(\d+\)$/, "");
  };

  const contractTypes = [
    { label: "Select contract type", value: " " },
    { label: "Master Service Agreement", value: "master_service" },
    { label: "Non-Disclosure Agreement", value: "non_disclosure" },
    { label: "Others", value: "unknown" },
  ];
  const handleContractTypeChange = (value: string) => {
    console.log("value i got in selecteddropdown", value);
    setSelectedContractType(value);
    if (value) {
      setButtonClickedWhileDisabled(false); // Reset the error state
    }
  };

  useEffect(() => {
    console.log("slected contract type in useefect,", selectedContractType);
  });
  const contentRef = useRef([]);
  // console.log("contentRef:", contentRef.current);
  const [expandedIndex, setExpandedIndex] = useState(null); // Track which playbook is expanded
  const [clampedIndexes, setClampedIndexes] = useState([]); // Track which playbooks are clamped

  useEffect(() => {
    // Check for clamped playbooks
    const clamped = playbookData.map((_, index) => {
      const ref = contentRef.current[index];
      // console.log(`Index ${index} scrollHeight:`, ref?.scrollHeight);
      return ref && ref.scrollHeight > 60; // Compare scrollHeight to 60px
    });
    // console.log("Expanded index:", expandedIndex);
    // console.log("Clamped indexes:", clamped);
    setClampedIndexes(clamped);
  }, [playbookData]);

  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Toggle the expand state for the specific playbook
  };

  console.log(error);

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <div style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
        {/* Upload Box */}
        <div
          style={{
            width: "273px",
            height: "220px",
            borderRadius: "8px",
            padding: "10px",
            textAlign: "center",
            // backgroundColor: playbookData.length > 0 ? "#effdf4" : "#F9FAFB",
            backgroundColor: playbookData.length > 0 ? "#effdf4" : uploadBoxBgColor,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "16px",
          }}
        >
          {/* Cloud Icon Box */}
          <div
            style={{
              width: "247px",
              height: "43px",
              border: "1.5px dashed #C3CBD8",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFFFFF",
              cursor: "pointer",
              marginTop: "0px",
            }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#e6e6e6")}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#FFFFFF")}
          >
            {/* Hidden File Input */}
            <input
              type="file"
              id="csv"
              accept=".csv, .docx, .pdf"
              required
              style={{
                position: "absolute",
                width: "247px",
                height: "43px",
                opacity: 0,
                cursor: "pointer",
              }}
              onChange={handleFileUpload}
            />
            <img
              src={playbookData.length > 0 ? fileIcon : cloudIcon}
              alt="Upload Icon"
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </div>
          {/* File Name Display */}
          {fileName && (
            <div
              style={{
                width: "100%",
                marginTop: "10px",
                padding: "8px",
                backgroundColor: "#F5F5F5",
                borderRadius: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  maxWidth: "80%",
                }}
              >
                <img src={fileIcon} alt="File" style={{ width: "16px", height: "16px" }} />
                <div style={{ position: "relative" }}>
                  <span
                    ref={fileNameRef}
                    style={{
                      fontSize: "12px",
                      color: "#13123E",
                      display: "block",
                      maxWidth: "150px",
                      overflow: "hidden",
                      textOverflow: isFileNameExpanded ? "clip" : "ellipsis",
                      whiteSpace: isFileNameExpanded ? "normal" : "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    {fileName}
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Show error message if file type is incorrect */}
          {error && <div style={{ color: "#EF4444", fontSize: "12px", fontWeight: "400" }}>{error}</div>}

          {/* Upload Message */}
          <p
            style={{
              fontSize: "12px",
              color: "#8A94A6",
              textAlign: "center",
              maxWidth: "247px",
              marginLeft: "0px",
              paddingTop: "8px",
              marginBottom: "20px",
            }}
          >
            <>
              Please upload your standard contract in, Docx, CSV and Pdf format only.
              <br />
              Max file size{" "}
              <strong style={{ color: " #0A0A22", fontSize: "12px", fontWeight: "400", fontStyle: "normal" }}>
                2 MB
              </strong>
            </>
          </p>

          {/* trash icon and succesful text */}
          {playbookData.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%", // Ensures it takes full width
              }}
            >
              {/* Left Section */}
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <img
                  src={tickIcon}
                  alt="tickicon"
                  style={{
                    width: "14px",
                    height: "14px",
                    cursor: "pointer",
                  }}
                  onClick={handleClearPlaybook}
                />
                <p
                  style={{
                    fontSize: "12px",
                    color: "#13123E",
                    fontStyle: "normal",
                    fontWeight: "400",
                    marginBottom: "0px",
                  }}
                >
                  10 MB completed
                </p>
              </div>

              {/* Right Section */}
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#F87171",
                    fontStyle: "normal",
                    fontWeight: "400",
                    marginBottom: "0px",
                    cursor: "pointer",
                  }}
                >
                  Delete
                </p>
                <img
                  src={trashIcon}
                  alt="trashicon"
                  style={{
                    width: "14px",
                    height: "14px",
                    cursor: "pointer",
                  }}
                  onClick={handleClearPlaybook}
                />
              </div>
            </div>
          )}

          {loading && (
            <div style={{ marginTop: "10px", textAlign: "center", width: "100%", position: "relative" }}>
              {/* Loader with Dynamic Progress Bar */}
              <div
                style={{
                  backgroundColor: "#E5E7EB",
                  height: "0.5rem",
                  width: "100%",
                  borderRadius: "0.25rem",
                  overflow: "hidden",
                  position: "relative",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#3B82F6",
                    height: "100%",
                    width: `${uploadProgress}%`,
                    transition: "width 0.3s ease-in-out",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
              {/* Loading Text */}
              <p style={{ fontSize: "12px", color: "#61607E", marginTop: "5px" }}>
                Adding Playbook, Please Wait! This can take up to 2 minutes.
              </p>
            </div>
          )}
        </div>
        {/* sample csv file section */}
        {!loading && playbookData.length === 0 && !isCsvFile && (
          <div
            style={{
              width: "263px",
              height: "70px",
              border: "1px solid #E7E7EC",
              borderRadius: "12px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFFFFF",
              cursor: "pointer",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "8px", marginLeft: "45px", marginTop: "10px" }}>
              <img src={sampleFileIcon} alt="icon" style={{ width: "16px", height: "16px" }} />
              <a
                href="https://devlegalgraph.blob.core.windows.net/app-files/Sample%20CSV%20Playbook.csv"
                download="Sample CSV Playbook.csv"
                style={{ margin: 0, fontSize: "12px", color: "#4A88EE", textDecoration: "none" }}
              >
                Download Sample CSV file
              </a>
            </div>
            <p style={{ marginTop: "4px", textAlign: "center", fontSize: "10px", color: "#61607E" }}>
              Download a Sample CSV File to see how Playbook
              <br />
              Standards are structured and formatted.
            </p>
          </div>
        )}
        {/*  csv file contracttype selection */}
        {playbookData.length === 0 && isCsvFile && (
          <div style={{ marginBottom: "16px" }}>
            <label
              htmlFor="contractType"
              style={{
                display: "block",
                marginBottom: "8px",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                color: "#08081A",
              }}
            >
              Please select your contract type
            </label>
            <Dropdown options={contractTypes} onSelect={handleContractTypeChange} value={selectedContractType} />
            <p
              style={{
                fontSize: "10px",
                fontWeight: "400",
                textAlign: "left",
                color: "#61607E",
                paddingTop: "5px",
              }}
            >
              Please select the contract type from the dropdown to proceed with your playbook upload
            </p>
          </div>
        )}

        {/* Show playbook data  if response available */}
        {playbookData.length > 0 && (
          <>
            <p
              style={{
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                color: "#61607E",
              }}
            >
              {playbookName}
            </p>
            <div
              style={{
                width: "262px",
                padding: "0px",
                marginLeft: "0px",
              }}
            >
              {/* Toggle area to see playbook details */}
              <div
                style={{
                  marginBottom: "16px",
                  display: "flex",
                  gap: "60px",
                  height: "2.75rem",
                  width: "100%",
                  padding: "9px",
                  borderRadius: "8px",
                  backgroundColor: "#ffff",
                  border: "1px solid #E7E7EC",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <img
                    src={isToggleOn ? eyeOpenIcon : eyeClosedIcon}
                    alt={isToggleOn ? "open" : "closed"}
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      color: "#08081A",
                      paddingTop: "15px",
                    }}
                  >
                    View Playbook Data
                  </p>
                </div>

                <ToggleExample onChange={setIsToggleOn} checked={isToggleOn} />
              </div>

              {/* playbook contents */}
              {isToggleOn &&
                playbookData.map((playbook, index) => (
                  <div
                    key={index}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "0.60rem",
                      marginBottom: "15px",
                      background: "#ffffff",
                      width: "270px",
                      maxHeight: "155px",
                      overflow: expandedIndex === index ? "auto" : "hidden",
                      position: "relative",
                    }}
                  >
                    {/* heading */}
                    <h4
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#424165",
                        marginBottom: "4px",
                      }}
                    >
                      {cleanClauseText(playbook.key)}
                    </h4>
                    <div style={{ position: "relative" }}>
                      <p
                        ref={(el) => (contentRef.current[index] = el)}
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "1.4",
                          color: "#61607E",
                          maxHeight: expandedIndex === index ? "100%" : "85px",
                          margin: "0",
                          overflow: "hidden",
                          mask:
                            expandedIndex === index
                              ? "none"
                              : "linear-gradient(to bottom, black 85%, transparent 100%)",
                          WebkitMask:
                            expandedIndex === index
                              ? "none"
                              : "linear-gradient(to bottom, black 85%, transparent 100%)",
                        }}
                      >
                        {playbook.standardAnswer}
                      </p>
                      {playbook.standardAnswer.length > 60 && ( // Check if content is long enough to show "See More"
                        <span
                          onClick={() => handleExpand(index)}
                          style={{
                            fontSize: "10px",
                            fontWeight: "400",
                            color: "#4A88EE",
                            cursor: "pointer",
                            position: "absolute",
                            bottom: "-8px",
                            right: "0",
                            background: "#f9f9f9",
                            paddingLeft: "4px",
                          }}
                        >
                          {expandedIndex === index ? "See Less" : "See More"}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
        {/* button and footer */}
        <div style={{ marginTop: "auto", marginLeft: "-27px" }}>
          {playbookData.length > 0 && (
            <div style={{ display: "flex", flexDirection: "column", marginBottom: "15px" }}>
              <button
                style={{
                  minWidth: "247px",
                  marginLeft: "30px",
                  padding: "10px 20px",
                  fontSize: "14px",
                  fontWeight: "500",
                  fontStyle: "normal",
                  backgroundColor: "#4A88EE",
                  color: "#fff",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => triggerRiskAnalysis()}
              >
                Start risk analysis
              </button>
            </div>
          )}

          {isCsvFile && (
            <div style={{ display: "flex", flexDirection: "column", marginBottom: "15px" }}>
              <button
                style={{
                  minWidth: "247px",
                  marginLeft: "30px",
                  padding: "10px 20px",
                  fontSize: "14px",
                  fontWeight: "500",
                  fontStyle: "normal",
                  backgroundColor: selectedContractType ? "#4A88EE" : "#A3ADFF",
                  color: "#fff",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={handleUploadButtonClick}
                disabled={!selectedContractType}
              >
                Continue
              </button>
              {buttonClickedWhileDisabled && !selectedContractType && (
                <p style={{ color: "red", fontSize: "12px", marginTop: "5px", marginLeft: "30px" }}>
                  Please select a contract type to proceed.
                </p>
              )}
            </div>
          )}
        </div>
        <div style={{ marginLeft: "15px" }}>
          <Footer />
        </div>
        {/* security information text
        {(loading || playbookData.length === 0) && (
          <p
            style={{
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: "400",
              color: "#424165",
              textAlign: "center",
              lineHeight: "1.5",
            }}
          >
            <span>256-bit AES Encryption. Your files are stored securely in</span>
            <br />
            <span>LegalGraph. Your data is not shared with any third party,</span>
            <br />
            <span>and you can delete your data anytime.</span>
          </p>
        )} */}
      </div>
    </div>
  );
};

export default UploadPlaybook;
