/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { MemoryRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import UserLogin from "../taskpane/pages/UserLogin/UserLogin";
import SignupPage from "./pages/SignupPage/SignupPage";
import Emailverification from "./pages/PasswordReset/Emailverification";
import OTPform from "./pages/PasswordReset/OTPform";
// import RiskAnalyse from "./pages/RiskAnalyse/RiskAnalyse";
import UploadPage from "./pages/UploadPage";
import EditClauseTerm from "./pages/EditClauseTerm";
import ReasoningPage from "./pages/ReasoningPage";
import LandingPage from "./pages/Landingpage/LandingPage";
import SettingsPage from "./pages/SettingsPage";
import ManagePlaybook from "./pages/ManagePlaybook/ManagePlaybook";
import AnalysePage from "./pages/AnalysePage/AnalysePage";
import ContactUs from "./pages/ContactUs/ContactUs";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import toast, { Toaster } from "react-hot-toast";
import { persistor } from "../store/store";

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); //tracks whether the user is logged in.
  useEffect(() => {
    // Check if token exists in localStorage to persist login
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    persistor.purge();
    toast.success("Logged out successfully");
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <Toaster />
      {!isAuthenticated ? (
        <Routes>
          <Route path="*" element={<UserLogin onLogin={() => setIsAuthenticated(true)} />} />
          <Route path="/signup" element={<SignupPage onSignup={() => setIsAuthenticated(true)} />} />
          <Route path="/sendotp" element={<Emailverification />} />
          <Route path="/verifyotp" element={<OTPform />} />
          <Route path="/resetpw" element={<PasswordReset />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/home" element={<LandingPage />} />
          <Route path="/settings" element={<SettingsPage onLogout={handleLogout} />} />
          <Route path="/manageplaybook" element={<ManagePlaybook />} />
          <Route path="/upload" element={<UploadPage />} />
          {/* <Route path="/analyze" element={<RiskAnalyse />} /> */}
          <Route path="/analyze" element={<AnalysePage />} />
          <Route path="/edit" element={<EditClauseTerm />} />
          <Route path="/reasoning" element={<ReasoningPage />} />
          <Route path="/info" element={<ContactUs />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
