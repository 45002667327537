/* eslint-disable no-unused-vars */
import React from "react";
import { Stack, IStackTokens } from "@fluentui/react/lib/Stack";
import { Toggle as FluentToggle } from "@fluentui/react/lib/Toggle"; // Alias to avoid naming conflict

const stackTokens: IStackTokens = { childrenGap: 10 };

interface ToggleExampleProps {
  onChange: (checked: boolean) => void;
  checked: boolean;
}

const ToggleExample: React.FC<ToggleExampleProps> = ({ onChange, checked }) => {
  return (
    <Stack tokens={stackTokens}>
      <FluentToggle
        checked={checked}
        onChange={(ev, newChecked) => {
          if (newChecked !== undefined) {
            onChange(newChecked);
          }
        }}
      />
    </Stack>
  );
};

export default ToggleExample;
