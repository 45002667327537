import React, { useState, useEffect } from "react";
import { MessageBarButton } from "@fluentui/react";
import { CheckmarkCircle24Regular } from "@fluentui/react-icons"; // Fluent UI Tick Mark Icon

interface ErrorPageProps {
  clause: string;
  actionType: "Accepted" | "Rejected" | "Saved";
  onUndo: () => void;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ clause, actionType, onUndo }) => {
  const [bgColor, setBgColor] = useState("#f5f5f5");
  const [textColor, setTextColor] = useState("#9292A6");

  useEffect(() => {
    const timer = setTimeout(() => {
      setBgColor("#F0FDF4");
      setTextColor("#15803D");
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      style={{
        display: "flex", //  flexbox for horizontal layout
        alignItems: "center", // Align items vertically centered
        // backgroundColor: "#f5f5f5",
        backgroundColor: bgColor,
        color: "black",
        padding: "4px",
        borderRadius: "8px",
        width: "100%", // Ensures the container uses full width
        marginLeft: "10px",
        marginBottom: "10px",
        transition: "background-color 0.3s ease",
      }}
    >
      <CheckmarkCircle24Regular
        style={{
          color: "#22C55E",
          marginRight: "8px", // Space between icon and text
        }}
      />
      <span
        style={{
          // color: "#9292A6",
          color: textColor,
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "500",
          flex: 1, //text takes available space
          whiteSpace: "normal", //text to wrap if it's too long
          transition: "color 0.3s ease",
        }}
      >
        {clause} {actionType}
      </span>
      <MessageBarButton
        onClick={onUndo}
        style={{
          color: "#22C55E",
          marginLeft: "auto", // Push the button to the far right
          backgroundColor: bgColor,
          border: "none",
        }}
      >
        Undo
      </MessageBarButton>
    </div>
  );
};

export default ErrorPage;
