import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import animationData from "../../../assets/Document.json";
import animationData2 from "../../../assets/first doc.json";
import step1 from "../../../assets/Stepper Components-1.png";
import step2 from "../../../assets/Stepper Components-2.png";
import step3 from "../../../assets/Stepper Components-3.png";
import step4 from "../../../assets/Stepper Components-4.png";
import step5 from "../../../assets/Stepper Components-5.png";
import step6 from "../../../assets/Stepper Components.png";
import Encryption from "../../../assets/Encryption.png";
import Data from "../../../assets/Data Security.png";
import Contract from "../../../assets/Your Contract.png";
import Secured from "../../../assets/Secured.png";

interface Step {
  title: string;
  animation: typeof animationData | typeof animationData2 | string;
}

function NewLoader() {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const steps: Step[] = [
    { title: "Reviewing key clauses and risks..", animation: animationData2 },
    // { title: "Amendment to Master Services Agreement", animation: animationData },
    { title: "Confidentiality Breach", animation: animationData },
    { title: "Indemnification", animation: animationData },
    { title: "Data Security & Remedies", animation: animationData },
    { title: "Termination Clause", animation: animationData },
    { title: "Limitations of Liability", animation: animationData },
    { title: "Warranties & Remedies", animation: animationData },
    { title: "Insurance", animation: animationData },
    { title: "Payment Terms", animation: animationData },
    { title: "Notices", animation: animationData },
    { title: "Securing your Data ...", animation: Encryption },
    { title: "Encryption in Progress ...", animation: Data },
    { title: "Your Contract is secured ... ", animation: Secured },
    { title: "Analysis results are ready ... ", animation: Contract },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
    }, 3000);
    return () => clearInterval(timer);
  }, [steps.length]);

  const currentStepData: Step = steps[currentStep];

  const getStepImage = (stepIndex: number): string => {
    const circleStep = stepIndex % 6;
    switch (circleStep) {
      case 0:
        return step1;
      case 1:
        return step2;
      case 2:
        return step3;
      case 3:
        return step4;
      case 4:
        return step6;
      default:
        return step6;
    }
  };

  const isImage = (animation: Step["animation"]): animation is string => {
    return typeof animation === "string";
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
        fontFamily: "Arial, sans-serif",
      }}
    >
      {/* <p style={{ fontSize: "10px", marginBottom: "4px" }}>{`${currentStep + 1}/${steps.length}`}</p> */}
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <img
          src={getStepImage(currentStep)}
          alt={`Step ${(currentStep % 6) + 1}`}
          style={{ width: "50px", height: "40px", objectFit: "contain" }}
        />
      </div>
      <p
        style={{
          fontSize: "16px",
          color: "#333",
          fontWeight: "bold",
          marginTop: "10px",
          transition: "opacity 0.5s ease",
        }}
      >
        {currentStepData.title}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isImage(currentStepData.animation) ? (
          <img
            src={currentStepData.animation}
            alt={currentStepData.title}
            style={{ width: "105px", height: "105px", objectFit: "contain", marginBottom: "5rem" }}
          />
        ) : (
          <Lottie
            animationData={currentStepData.animation}
            loop
            autoplay
            style={{ width: "95px", height: "135px", backgroundColor: "transparent", marginBottom: "5rem" }}
          />
        )}
        <p style={{ fontSize: "14px", color: "#4A88EE", fontWeight: "bold", paddingTop: "5px" }}>
          The whole process usually
          <br />
          takes up to 3-4 minutes...
        </p>
      </div>
    </div>
  );
}

export default NewLoader;
