import React from "react";
import { useNavigate } from "react-router-dom";
import SidebarMain from "../components/SidebarMain";
import UploadPlaybook from "../components/UploadPlaybook";
import backIcon from "../../../assets/back.png";

const UploadPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{ display: "flex", height: "100vh", overflow: "auto", scrollbarWidth: "none", msOverflowStyle: "none" }}
    >
      <SidebarMain />
      <div
        style={{
          flexGrow: 1,
          padding: "20px",
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: "5px",
          }}
          onClick={() => navigate("/manageplaybook")}
        >
          <img src={backIcon} alt="Back" style={{ width: "24px", height: "24px", marginRight: "10px" }} />
          <span style={{ fontSize: "14px", fontWeight: "400", fontStyle: "normal", color: "#13123E" }}>
            Upload new playbook
          </span>
        </div>
        <UploadPlaybook />
      </div>
    </div>
  );
};

export default UploadPage;
