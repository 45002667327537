/* eslint-disable no-undef */

// pages/Landingpage/LandingPage.tsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import SidebarMain from "../../components/SidebarMain";
import Footer from "../../components/Footer";
import AddContractImage from "../../../../assets/AddContract.png";
import Lottie from "lottie-react";
import animationData from "../../../../assets/Search document.json";
import { getDocumentMetadata, getPageData } from "../../../services/document-service";
import { uploadContentAsPdf } from "../../../services/upload-to-backend";
import { setFileResponse } from "../../../store/fileSlice";
import { contractFileInfo } from "../../../models/contractFileData";
import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();
const apiUrl = process.env.API_URL;

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isContractOpen, setIsContractOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const storedUserId = localStorage.getItem("activeUserId");
  const analysisResults = useSelector((state: RootState) => state.analysis.analysisResults);

  useEffect(() => {
    const checkContractState = async () => {
      if (Office.context.document) {
        try {
          await Word.run(async (context) => {
            const docBody = context.document.body;
            docBody.load("text");
            await context.sync();

            if (docBody.text.length > 0) {
              setIsContractOpen(true);
              setIsLoading(true);
              detectContractType();
            } else {
              setIsContractOpen(false);
            }
          });
        } catch (error) {
          console.error("Error checking document state:", error);
        }
      } else {
        setIsContractOpen(false);
      }
    };

    checkContractState();
  }, []);

  const detectContractType = async () => {
    try {
      const fileName = await getDocumentMetadata();
      const textContent = await getPageData();
      const response = await uploadContentAsPdf(textContent, fileName);
      if (response.success && response.fileIds?.length > 0) {
        const files: contractFileInfo[] = response.fileIds.map((file) => ({
          fileId: file.fileId,
          contractName: file.ContractName,
          code: file.code,
          name: file.name,
          docType: file.docType,
          docSize: file.docSize,
          old: file.old,
        }));
        dispatch(setFileResponse(files));
        const detectedCode = response.fileIds[0]?.code;
        const res = await activateAutoPlaybook(detectedCode);
        if (res.data.success === true) {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log("error obtained in detecttype", error);
    }
  };

  const activateAutoPlaybook = async (contractTypeCode: string) => {
    const payload = { userId: storedUserId, contractTypeCode };
    try {
      const response = await axios.post(`${apiUrl}/api/v1/add-in/auto-playbook-activation`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  const triggerRiskAnalysis = () => {
    navigate("/analyze");
  };

  const renderAnalysisSummary = () => {
    if (!analysisResults) return null;
    // const riskCount = analysisResults.riskClauses?.length || 0;
    // const missingCount = analysisResults.missingClauses?.length || 0;
    // const favCount = analysisResults.favClauses?.length || 0;
    return (
      <div>
        <p style={{ fontSize: "20px", fontWeight: "600", color: "#424165" }}>Analysis Complete</p>
        {/* <p>Risks: {riskCount}</p>
        <p>Missing Clauses: {missingCount}</p> */}
        {/* <p>Favorable Clauses: {favCount}</p> */}
        <button
          style={{
            minWidth: "247px",
            padding: "10px 20px",
            fontSize: "14px",
            fontWeight: "500",
            backgroundColor: "#4A88EE",
            color: "#fff",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/analyze")}
        >
          View Detailed Analysis
        </button>
      </div>
    );
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <SidebarMain />
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {!isContractOpen && (
            <div>
              <img src={AddContractImage} alt="Add Contract" style={{ width: "75px", height: "75px" }} />
              <p
                style={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  marginBottom: "10px",
                  color: "#424165",
                }}
              >
                Please open a document to start the AI analysis
              </p>
            </div>
          )}

          {isContractOpen && isLoading && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Lottie animationData={animationData} loop autoplay style={{ width: "85px", height: "85px" }} />
              <p
                style={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  marginBottom: "10px",
                  color: "#424165",
                }}
              >
                Your document is loading this may take up to 1 minute, <br />
                Please Wait ...
              </p>
            </div>
          )}

          {isContractOpen && !isLoading && !analysisResults && (
            <div>
              <p
                style={{
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  marginBottom: "10px",
                  color: "#424165",
                }}
              >
                Ready to analyze
              </p>
              <button
                style={{
                  minWidth: "247px",
                  padding: "10px 20px",
                  fontSize: "14px",
                  fontWeight: "500",
                  fontStyle: "normal",
                  backgroundColor: "#4A88EE",
                  color: "#fff",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={triggerRiskAnalysis}
              >
                Start Analysis
              </button>
            </div>
          )}

          {isContractOpen && !isLoading && analysisResults && renderAnalysisSummary()}
        </div>
        {isContractOpen && !isLoading && (
          <div style={{ marginTop: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
