import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import backIcon from "../../../../assets/back.png";

const OTPform: React.FC = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [error, setError] = useState("");

  const handleChange = (index: number, value: string) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const otpValue = otp.join("");
    if (otpValue.length !== 4) {
      // setError("Please enter a valid OTP.");
      return;
    }
    // console.log("Submitted OTP:", otpValue);
    navigate("/resetpw");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "100vh",
        padding: "0rem",
        backgroundColor: "#ffffff",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "400px",
          padding: "1.15rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: "12px",
          }}
          onClick={() => navigate("/sendotp")}
        >
          <img src={backIcon} alt="Back" style={{ width: "24px", height: "24px", marginRight: "10px" }} />
          <span style={{ fontSize: "14px", fontWeight: "400", fontStyle: "normal", color: "#13123E" }}>Back</span>
        </div>

        <div style={{ textAlign: "left" , marginBottom: "12px" }}>
          <p style={{ fontSize: "16px", fontWeight: "700", color: "#13123E" , marginBottom: "0px" }}>Password Reset</p>
          <p style={{ fontSize: "12px", fontWeight: "400", color: "#13123E" }}>We sent a code to john12@gmail.com</p>
        </div>

        {error && <p style={{ color: "red", fontSize: "12px", marginBottom: "10px" }}>{error}</p>}

        <form onSubmit={handleSubmit}>
          <label
            style={{
              display: "block",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              marginBottom: "5px",
              color: "#08081A",
            }}
          >
            Enter OTP
          </label>

          <div style={{ display: "flex", gap: "0px", marginBottom: "4px" }}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                inputMode="numeric"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                style={{
                  width: "100%",
                  height: "40px",
                  textAlign: "center",
                  fontSize: "16px",
                  border: "1px solid #ddd",
                  borderRadius: "0px",
                  color: "#13123E",
                  backgroundColor: "#ffffff",
                }}
              />
            ))}
          </div>

          <p
            style={{
              fontSize: "12px",
              color: "#13123E",
              marginBottom: "24px",
              cursor: "pointer",
            }}
          >
            Didnt receive the OTP? Resend code in 00:59
          </p>

          <button
            type="submit"
            style={{
              display: "block",
              width: "100%",
              height: "40px",
              backgroundColor: "#4A88EE",
              color: "#ffffff",
              borderRadius: "8px",
              fontSize: "12px",
              fontWeight: 500,
              textAlign: "center",
              border: "none",
              cursor: "pointer",
            }}
          >
            Continue
          </button>
        </form>
      </div>
      <div style={{ marginLeft: "1rem" }}>
        <Footer />
      </div>
    </div>
  );
};

export default OTPform;
