/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { jwtDecode } from "jwt-decode";
import Footer from "../../components/Footer";
import { HandleLogin } from "../../../services/userAuth-services";
import { autoLogoutUser, setUser } from "../../../store/userDataSlice";
// import eyeOpen from "../../../../assets/eyeOpen.png";
// import eyeClosed from "../../../../assets/eyeclosed.png";
import toast from "react-hot-toast";
import { persistor } from "../../../store/store";
import { clearAnalysisData } from "../../../store/analysisSlice";

interface LoginProps {
  onLogin: () => void;
}

interface CustomJwtPayload {
  email: string;
  username: string;
  userId: string;
  exp: number;
}

const UserLogin: React.FC<LoginProps> = ({ onLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  // const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setupAutoLogout = (token: string) => {
    try {
      const decodedToken = jwtDecode<CustomJwtPayload>(token);
      const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
      const timeUntilExpiration = expirationTime - Date.now();

      if (timeUntilExpiration <= 0) {
        // Token is already expired
        handleLogout();
        return;
      }
      // Set actual logout at expiration time
      setTimeout(() => {
        handleLogout();
        toast.error("Session expired. You have been logged out.");
      }, timeUntilExpiration);
    } catch (error) {
      console.error("Error setting up auto logout:", error);
      handleLogout();
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(autoLogoutUser());
    persistor.purge();

    toast.error("You have been logged out.");

    setTimeout(() => {
      navigate("/");
    }, 3000);
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setupAutoLogout(token);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email format").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      setError(null);
      setIsLoading(true);
      try {
        // Clear any existing analysis data
        dispatch(clearAnalysisData());
        const response = await HandleLogin(values.email, values.password);
        if (response?.status === 200) {
          const { token } = response.data;
          const decodedToken = jwtDecode<CustomJwtPayload>(token);
          const { email, username, userId } = decodedToken;
          dispatch(
            setUser({
              email: decodedToken.email,
              username: decodedToken.username,
              userId: decodedToken.userId,
              token: token,
            })
          );
          localStorage.setItem("authToken", token);
          localStorage.setItem("activeUserId", userId);
          localStorage.setItem("activeMail", email);
          localStorage.setItem("activeName", username);

          onLogin();
          toast.success("Login successful");
        } else {
          setError(response?.data?.message || "Invalid login credentials");
        }
      } catch (err) {
        console.log("error ocuured in login", err.message);
        {
          err.message === "Network Error"
            ? setError("Network Connectivity Issue")
            : err.message === "Request failed with status code 401"
              ? setError("We couldn’t log you in. Please check your email and password and try again")
              : setError("Invalid login credentials");
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#ffffff",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          width: "100%",
          maxWidth: "400px",
          padding: "2rem",
        }}
      >
        {/* Header */}
        <div style={{ textAlign: "left", marginBottom: "20px" }}>
          <p style={{ fontSize: "16px", fontWeight: "bold", fontStyle: "normal", color: "#13123E" }}>
            Welcome to Legal Graph - AI
          </p>
        </div>

        {/* Form */}
        <form onSubmit={formik.handleSubmit}>
          {/* Email Label and Input */}
          <label
            htmlFor="email"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              fontStyle: "normal",
              color: "#08081A",
            }}
          >
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{
              width: "100%",
              height: "35px",
              padding: "10px",
              marginBottom: "10px",
              fontSize: "12px",
              borderRadius: "4px",
              border: "1px solid #ddd",
              fontStyle: "normal",
              fontWeight: "400",
              color: "black",
            }}
          />
          {formik.touched.email && formik.errors.email && (
            <p style={{ color: "red", fontSize: "12px", marginBottom: "10px" }}>{formik.errors.email}</p>
          )}

          {/* Password Label and Input with Eye Icon */}
          <label
            htmlFor="password"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              fontStyle: "normal",
              color: "#08081A",
            }}
          >
            Password
          </label>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <input
              id="password"
              name="password"
              type={"password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                width: "100%",
                height: "35px",
                padding: "10px",
                fontSize: "12px",
                borderRadius: "4px",
                border: "1px solid #ddd",
                fontFamily: "'SF Pro', sans-serif",
                color: "black",
              }}
            />
            {/* <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: "absolute",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              {showPassword ? (
                <img
                  src={eyeClosed}
                  alt="Hide password"
                  aria-label="hide password"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <img
                  src={eyeOpen}
                  alt="Show password"
                  aria-label="show password"
                  style={{ width: "20px", height: "20px" }}
                />
              )}
            </button> */}
          </div>
          {formik.touched.password && formik.errors.password && (
            <p style={{ color: "red", fontSize: "12px", marginBottom: "10px" }}>{formik.errors.password}</p>
          )}

          {/* Remember Me and Forgot Password */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div style={{ display: "flex" }}>
              <input id="remember-me" type="checkbox" style={{ marginRight: "5px" }} />
              <label
                htmlFor="remember-me"
                style={{
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  cursor: "pointer",
                  color: "#111038",
                }}
              >
                Remember me
              </label>
            </div>
            <Link
              to="/sendotp"
              style={{
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                color: "#111038",
                textDecoration: "none",
              }}
            >
              Forgot Password?
            </Link>
          </div>

          {/* Error message */}
          {error && (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                marginBottom: "10px",
              }}
            >
              {error}
            </p>
          )}

          {/* Login Button */}
          <button
            type="submit"
            style={{
              display: "block",
              width: "100%",
              height: "40px",
              backgroundColor: "#4A88EE",
              color: "#ffffff",
              borderRadius: "4px",
              fontStyle: "normal",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "14.32px",
              letterSpacing: "0.005em",
              textAlign: "center",
              border: "none",
              cursor: "pointer",
              marginBottom: "20px",
            }}
          >
            {isLoading ? "Loading..." : "Log In"}
          </button>

          {/* Footer Text */}
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              fontFamily: "'SF Pro', sans-serif",
              marginBottom: "0",
              color: "#9292A6",
            }}
          >
            Don't have an account?{" "}
            <Link
              to="/signup" // Replace with your desired route
              style={{
                color: "#4A88EE",
                textDecoration: "none",
                fontWeight: "500",
                fontSize: "12px",
              }}
            >
              Create one
            </Link>
          </p>
          {/* Network Connectivity Issue Box */}
          {error === "Network Connectivity Issue" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#FEFCE8",
                borderRadius: "4px",
                border: "1px solid #FEFCE8",
                textAlign: "center",
              }}
            >
              <p style={{ color: "#646464", fontSize: "12px", margin: "0" }}>
                <span style={{ fontSize: "16px", display: "block", marginBottom: "4px" }}>⚠️</span>
                <p style={{ fontWeight: "bold", color: "black", margin: "0", fontSize: "14px" }}>{error}</p>
                We’re having trouble connecting to the server. Please check your internet connection and try again.
              </p>
            </div>
          )}
        </form>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Footer />
      </div>
    </div>
  );
};

export default UserLogin;
