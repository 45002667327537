import React from "react";
import { useNavigate } from "react-router-dom";

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="container w-100 text-center mt-1">
        <img
          src="https://camo.githubusercontent.com/de70c9e44ba99ad3286fe79d2a48f867f171fbe6dc8e8d2f16d037be5f7f75d8/68747470733a2f2f6c6567616c6772617068616970726f642e626c6f622e636f72652e77696e646f77732e6e65742f6170702d66696c65732f6c6561695f776f72645f6e6f5f62672e706e67"
          alt="LeAi+Word"
          style={{ width: "50%" }}
        />
        <h3 className="mt-1">AI Boost to your contracts!</h3>
      </div>
      <div className="temp-actions-holder text-dark mt-4">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <button
                type="button"
                className="btn btn-primary"
                style={{ width: "110%" }}
                onClick={() => navigate("/generate")}
              >
                Generate Playbook
              </button>
            </div>
            <div className="col-4">
              <button
                type="button"
                className="btn btn-primary"
                style={{ width: "110%" }}
                onClick={() => navigate("/analyze")}
              >
                Analyze Contract
              </button>
            </div>
            <div className="col-4">
              <button
                type="button"
                className="btn btn-secondary"
                style={{ width: "110%" }}
                onClick={() => navigate("/settings")}
              >
                Playbook Settings
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
