/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@store/store";
import { setRedliningEnabled } from "../../store/toggleSlice";
import Dropdown from "./Dropdown";
import ToggleExample from "./Toggle";
import infoIcon from "../../../assets/infoicon.png";
import { getDocumentMetadata, getPageData } from "../../services/document-service";
import { uploadContentAsPdf } from "../../services/upload-to-backend";
import { contractFileInfo } from "../../models/contractFileData";
import { setFileResponse } from "../../store/fileSlice";
import axios from "axios";
import { removeSidePanelComments, removeTextWord, undoStrikeOutAndReplaceInDoc } from "../../commands/commands";
import toast from "react-hot-toast";
const autoUrl = process.env.API_URL;

const MasterSettings: React.FC = () => {
  const dispatch = useDispatch();
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const storedUserId = localStorage.getItem("activeUserId");
  const [isLoading, setIsLoading] = useState(false);
  const [isContractOpen, setIsContractOpen] = useState<boolean>(false);

  const contractTypes = [
    { label: "Master Service Agreement", value: "master_service" },
    { label: "Non-Disclosure Agreement", value: "non_disclosure" },
    { label: "Other", value: "unknown" },
  ];

  const handleContractTypeChange = (value: string) => {
    console.log("Selected contract type:", value);
  };

  const detectedType_fromredux = useSelector((state: RootState) => state.files.fileData[0]);
  const isRedliningEnabled = useSelector((state: RootState) => state.toggle.isRedliningEnabled);
  const availableClauses = useSelector((state: RootState) => state.analysis.availableClauses);
  console.log(availableClauses);
  const analysisResults = useSelector((state: RootState) => state.analysis.analysisResults);
  console.log(analysisResults);

  useEffect(() => {
    setIsToggleOn(isRedliningEnabled);
  }, [isRedliningEnabled]);

  const removeTexts = async () => {
    await availableClauses.riskClauses.map(async (clause) => {
      // Revert strikethrough, color, and remove replacement text in the document
      await undoStrikeOutAndReplaceInDoc(clause.location, clause.updatedContext || "");

      // Remove any comments added for this clause
      await removeSidePanelComments();

      // Remove the green replacement text added during "Accept"
      if (clause.updatedContext && clause.updatedContext.trim().length > 0) {
        await removeTextWord(clause.updatedContext);
      }
      return clause;
    });
  };

  const handleToggleChange = async (value: boolean) => {
    try {
      if (value) {
        await activateAutoPlaybookOn();
      } else {
        await removeTexts();
        await activateAutoPlaybookOff();
      }
      setIsToggleOn(value);
      dispatch(setRedliningEnabled(value));
    } catch (error) {
      console.error("Error handling toggle change:", error);
      toast.error("Something Went Wrong");
      // Revert toggle state on error
      setIsToggleOn(!value);
    }
  };

  const handleSaveChanges = async () => {
    try {
      if (isToggleOn) {
        await activateAutoPlaybookOn();
        toast.success("Auto redlining enabled ");
      } else {
        await activateAutoPlaybookOff();
        toast.success("Auto redlining disabled ");
      }
      dispatch(setRedliningEnabled(isToggleOn));
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error("Failed to save changes");
    }
  };

  useEffect(() => {
    const checkContractState = async () => {
      if (Office.context.document) {
        try {
          await Word.run(async (context) => {
            const docBody = context.document.body;
            docBody.load("text");
            await context.sync();

            if (docBody.text.length > 0) {
              setIsContractOpen(true);
              setIsLoading(true);
              detectContractType();
            } else {
              setIsContractOpen(false);
            }
          });
        } catch (error) {
          console.error("Error checking document state:", error);
        }
      } else {
        setIsContractOpen(false);
      }
    };

    checkContractState();
  }, []);

  const detectContractType = async () => {
    try {
      const fileName = await getDocumentMetadata();
      const textContent = await getPageData();
      const response = await uploadContentAsPdf(textContent, fileName);
      if (response.success && response.fileIds?.length > 0) {
        const files: contractFileInfo[] = response.fileIds.map((file) => ({
          fileId: file.fileId,
          contractName: file.ContractName,
          code: file.code,
          name: file.name,
          docType: file.docType,
          docSize: file.docSize,
          old: file.old,
        }));
        dispatch(setFileResponse(files));
      }
    } catch (error) {
      console.log("error obtained in detecttype", error);
    }
  };

  const activateAutoPlaybookOff = async () => {
    const payload = { userId: storedUserId, auto_redline: false };
    try {
      const response = await axios.post(`${autoUrl}/api/v1/user/update-user`, payload);
      console.log("Auto playbook OFF response:", response);
      return response;
    } catch (error) {
      console.error("Error making API call:", error);
      throw error;
    }
  };

  const activateAutoPlaybookOn = async () => {
    const payload = { userId: storedUserId, auto_redline: true };
    try {
      const response = await axios.post(`${autoUrl}/api/v1/user/update-user`, payload);
      console.log("Auto playbook ON response:", response);
      return response;
    } catch (error) {
      console.error("Error making API call:", error);
      throw error;
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "260px" }}>
      <div style={{ flexGrow: 1 }}>
        {/* Contract Type */}
        <label
          htmlFor="contractType"
          style={{
            display: "block",
            marginBottom: "8px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#08081A",
          }}
        >
          Change Contract Type
        </label>
        <Dropdown
          options={contractTypes}
          onSelect={handleContractTypeChange}
          initialValue={detectedType_fromredux?.name}
        />
        <p
          style={{
            marginTop: "8px",
            fontSize: "12px",
            color: "#555",
          }}
        >
          Choose the type of contract you re analyzing (e.g., Master Service Agreement, Statement of Work, etc.).
        </p>

        {/* Redlining */}
        <label
          htmlFor="autoredline"
          style={{
            display: "block",
            marginBottom: "8px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#08081A",
          }}
        >
          Redlining
        </label>
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            gap: "8px",
            height: "32px",
            width: "260px",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "6px",
            paddingRight: "6px",
            borderRadius: "8px",
            backgroundColor: "#f5f5f5",
            position: "relative",
          }}
        >
          <ToggleExample onChange={handleToggleChange} checked={isToggleOn} />
          <p
            style={{
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              color: "#61607e",
            }}
          >
            Auto Start Contract Redlining
          </p>
          <div
            style={{ position: "relative", display: "inline-block" }}
            onMouseEnter={() => setShowInfoTooltip(true)}
            onMouseLeave={() => setShowInfoTooltip(false)}
          >
            <img
              src={infoIcon}
              alt="infoicon"
              style={{
                width: "16px",
                height: "16px",
                marginLeft: "9.3px",
                cursor: "pointer",
                marginBottom: "8px",
              }}
            />
            {showInfoTooltip && (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  border: "1px solid #E7E7EC",
                  borderRadius: "4px",
                  padding: "8px",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#424165",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  zIndex: 9999,
                  width: "250px",
                  textAlign: "center",
                }}
              >
                Enable this option to automatically start redlining of the contract.
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={{ padding: "8px", marginTop: "auto", display: "flex", justifyContent: "center", marginLeft: "15px" }}>
        <button
          style={{
            borderRadius: "8px",
            backgroundColor: "#4A88EE",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            cursor: "pointer",
            width: "250px",
            fontSize: "14px",
            fontWeight: "500",
          }}
          onClick={handleSaveChanges}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default MasterSettings;
