import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";
// import AnalysisIcon from "../../../assets/analysis.png";
// import analysis_Selected from "../../../assets/analysis_selected.png";
// import analysis_hover from "../../../assets/analysis_hover.png";
import homeIcon from "../../../assets/home.png";
import home_selected from "../../../assets/home_selected.png";
import home_hover from "../../../assets/home_hover (1).png";
import playbookIcon from "../../../assets/playbook.png";
import playbook_hover from "../../../assets/playbook_hover (1).png";
import playbook_selected from "../../../assets/playbook_Seelected.png";
import infoIcon from "../../../assets/info.png";
import info_selected from "../../../assets/info_Selected.png";
import info_hover from "../../../assets/info_hover (1).png";
import settings from "../../../assets/settings.png";
import settings_selected from "../../../assets/settings_Selected.png";
import settings_hover from "../../../assets/settings_hover (1).png";

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

const SidebarMain: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hovered, setHovered] = useState<string>("");

  const handleIconState = (iconName: string, isHover: boolean = false) => {
    if (isHover) {
      setHovered(iconName);
    } else {
      setHovered("");
    }
  };

  const routeIconMapping: { [key: string]: string } = {
    "/home": "home",
    "/settings": "settings",
    "/manageplaybook": "manageplaybook",
    "/upload": "manageplaybook",
    "/info": "info",
    // "/analyze": "info",
  };

  const active = routeIconMapping[location.pathname] || "";

  const getIconSrc = (iconName: string, defaultIcon: string, selectedIcon: string, hoverIcon: string) => {
    if (active === iconName) return selectedIcon;
    if (hovered === iconName) return hoverIcon;
    return defaultIcon;
  };

  const menuItems = [
    {
      name: "home",
      label: "Home",
      icon: homeIcon,
      selected: home_selected,
      hover: home_hover,
      route: "/home",
    },
    {
      name: "settings",
      label: "Settings",
      icon: settings,
      selected: settings_selected,
      hover: settings_hover,
      route: "/settings",
    },
    {
      name: "manageplaybook",
      label: "Manage Playbook",
      icon: playbookIcon,
      selected: playbook_selected,
      hover: playbook_hover,
      // route: "/manageplaybook",
      route: location.pathname.startsWith("/upload") ? "/upload" : "/manageplaybook",
    },
    {
      name: "info",
      label: "Info",
      icon: infoIcon,
      selected: info_selected,
      hover: info_hover,
      route: "/info",
    },
  ];

  return (
    <div
      style={{
        width: "46px",
        minHeight: "130vh",
        backgroundColor: "#f5f5f5",
        color: "white",
        padding: "20px",
      }}
    >
      {menuItems.map((item) => {
        const tooltipId = useId(item.name);

        return (
          <TooltipHost
            key={item.name}
            content={item.label}
            id={tooltipId}
            calloutProps={calloutProps}
            styles={hostStyles}
          >
            <div
              style={{
                marginLeft: "-20px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                paddingBottom: "26px",
                marginTop: "-20px",
              }}
              onClick={() => {
                handleIconState(item.name);
                if (item.route) navigate(item.route);
              }}
              onMouseEnter={() => handleIconState(item.name, true)}
              onMouseLeave={() => setHovered("")}
            >
              <img
                src={getIconSrc(item.name, item.icon, item.selected, item.hover)}
                alt={item.label}
                style={{ width: "46px", height: "46px" }}
              />
            </div>
          </TooltipHost>
        );
      })}
    </div>
  );
};

export default SidebarMain;
