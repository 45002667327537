import React from "react";
// import ProgressBar from "./ProgressBar";
import NewLoader from "./NewLoader";

const Loader: React.FC = () => {
  return (
    <>
      {/* <ProgressBar /> */}
      <NewLoader />
      {/* Did You Know Card */}
      <div
        style={{
          backgroundColor: "#FFF7E6",
          borderRadius: "8px",
          padding: "15px 20px",
          // margin: "20px",
          color: "#D65A00",
          fontSize: "14px",
          textAlign: "left", // Ensures multiline alignment starts from the same position
          maxWidth: "257px", // Matches the width of the inner box
          marginLeft: "0px",
          marginTop: "95px",
        }}
      >
        <strong>Did you know?</strong>
        <div
          style={{
            color: "black",
            fontSize: "12px",
            marginTop: "8px", // Adds space between the two texts
            fontStyle: "normal",
          }}
        >
          LegalGraph AI speeds up contract reviews by 2x, giving you more time to focus on closing deals.
        </div>
      </div>
    </>
  );
};

export default Loader;
