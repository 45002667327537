/* eslint-disable no-undef */
import ErrorPage from "../ErrorPage/ErrorPage";
import infoIcon from "../../../../assets/infoicon.png";
import indicatorIcon from "../../../../assets/indicator.png";
import Loader from "../../components/Loader";
import React, { useState, useEffect, useRef } from "react";
import { RootState } from "@store/store";
import { useDispatch, useSelector } from "react-redux";
import SidebarMain from "../../components/SidebarMain";
import AnalysisClausebox from "../../components/AnalysisClausebox";
import Footer from "../../components/Footer";
import {
  setRedlineHeader,
  setCategorywiseClauses,
  setActiveData,
  setAnalysisPayload,
  clearAnalysisData,
  setAnalysisResults,
} from "../../../store/analysisSlice";
import { getDocumentMetadata, getPageData, searchAndScroll } from "../../../services/document-service";
import { analyzePlaybook, uploadContentAsPdf } from "../../../services/upload-to-backend";
import {
  addCommentsToDoc,
  processLocationData,
  removeSidePanelComments,
  removeTextWord,
  strikeOutAndReplaceInDoc,
  undoStrikeOutAndReplaceInDoc,
} from "../../../commands/commands";
import { ClauseCategory } from "../../../models/analysisData";
import { useLocation } from "react-router-dom";
import axios from "axios";

type Clause = {
  category: string;
  key: string;
  updatedContext: string;
  location: string;
  analysis: string;
  standardAnswer?: string;
  comment?: string;
  page?: number;
};

type RemovedClause = {
  clause: Clause;
  type: "risks" | "missing";
  index: number;
  actionType: "Accepted" | "Rejected";
};

const AnalysePage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"risks" | "missing">("risks");
  const [clauseData, setClauseData] = useState<{
    risks: Clause[];
    missing: Clause[];
    // favourable: Clause[];
  }>({
    risks: [],
    missing: [],
    // favourable: [],
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const [showMessage, setShowMessage] = useState<{ clause: Clause; actionType: "Accepted" | "Rejected" } | null>(null);
  const [lastRemovedClause, setLastRemovedClause] = useState<RemovedClause | null>(null);
  const [originalIndexes, setOriginalIndexes] = useState<{ [key: string]: number }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // Redux selectors
  // const riskData_fromredux = useSelector((state: RootState) => state.analysis.riskData);
  const availableClauses_fromredux = useSelector((state: RootState) => state.analysis.availableClauses);
  // const activeData_fromredux = useSelector((state: RootState) => state.analysis.activeData);
  // const analysisPayload_fromredux = useSelector((state: RootState) => state.analysis.analysisPayload);
  // const lastAnalysisTimestamp = useSelector((state: RootState) => state.analysis.lastAnalysisTimestamp);

  const userId = localStorage.getItem("activeUserId");
  const hasInitialized = useRef(false);
  const analysisResults = useSelector((state: RootState) => state.analysis.analysisResults);
  const availableClauses = useSelector((state: RootState) => state.analysis.availableClauses);
  const storedUserId = localStorage.getItem("activeUserId");
  const hasProcessedRef = useRef<boolean>(false);
  const autoUrl = process.env.API_URL;
  const [isAutoRedline, setIsAutoRedline] = useState<boolean>(false);

  // Effect to clear analysis data and trigger new analysis when component mounts
  useEffect(() => {
    // If coming back from reasoning page, just use existing data
    if (location.state?.from === "reasoning" && analysisResults) {
      // formatClauseData(analysisResults);
      console.log(availableClauses_fromredux,'asdasdas')
      formatClauseData(availableClauses_fromredux);
      return;
    }

    // Only initialize once when not coming from reasoning
    if (!hasInitialized.current) {
      hasInitialized.current = true;
      dispatch(clearAnalysisData());
      setIsLoading(true);
      analyzeContract();
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (hasProcessedRef.current) return;
      const payload = { userId: storedUserId };
      try {
        const result = await axios.post(`${autoUrl}/api/v1/user/get-user`, payload);
        console.log(result);
        const isAutoRedline = Boolean(result.data.data[0].auto_redline); // Convert to boolean
        setIsAutoRedline(isAutoRedline);
        if (isAutoRedline && availableClauses?.riskClauses?.length > 0) {
          try {
            const processedClauses = await Promise.all(
              availableClauses.riskClauses.map(async (clause) => {
                await searchAndScroll(clause.location || "");
                if (clause.location && clause.updatedContext) {
                  await strikeOutAndReplaceInDoc(clause.location, clause.updatedContext);
                  if (clause.comment && clause.comment.trim().length > 0) {
                    await addCommentsToDoc(clause.location, clause.comment);
                  }
                }
                return clause;
              })
            );

            const updatedClauses = {
              ...availableClauses,
              riskClauses: processedClauses,
            };
            dispatch(setCategorywiseClauses(updatedClauses));
            dispatch(
              setAnalysisResults({
                ...analysisResults,
                riskClauses: processedClauses,
              })
            );
            // Mark processing as done
            hasProcessedRef.current = true;
          } catch (error) {
            console.error("Error processing risk clauses:", error);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [availableClauses, dispatch, analysisResults, storedUserId]);

  const formatClauseData = (data: any) => {
    console.log(data,'checkkk')
    const formattedData = {
      risks: data.riskClauses.map((clause: any) => ({
        category: "contradictory",
        key: clause.key || clause.id,
        updatedContext: clause.context || clause.updatedContext,
        location: clause.location,
        comment: clause.comment,
        standardAnswer: clause.standardAnswer,
        analysis: clause.analysis || "",
        action: clause.action,
        page: clause.location?.[0]?.page || null,
      })),
      missing: (data.missingClauses || []).map((clause: any) => ({
        category: "missing",
        key: clause.key || "",
        updatedContext: clause.standardAnswer || "",
        location: clause.location,
        comment: clause.comment,
        standardAnswer: clause.standardAnswer || "",
        analysis: clause.analysis || "",
        page: clause.location?.[0]?.page || null,
      })),
      // favourable: (data.favClauses || []).map((clause: any) => ({
      //   category: "favourable",
      //   key: clause.key || "",
      //   updatedContext: clause.context || clause.updatedContext,
      //   location: clause.location,
      //   comment: clause.comment,
      //   standardAnswer: clause.standardAnswer,
      //   analysis: clause.analysis || "",
      //   page: clause.location?.[0]?.page || null,
      // })),
    };
    setClauseData(formattedData);
  };

  const analyzeContract = async () => {
    try {
      setErrorMessage(null);
      const fileName = await getDocumentMetadata();
      const textContent = await getPageData();
      const convertAndUploadResponse = await uploadContentAsPdf(textContent, fileName);
      const playbookAnalysis = await analyzePlaybook(convertAndUploadResponse);
      console.log("Playbook Analysis : ", playbookAnalysis);

      if (playbookAnalysis.success) {
        const processedData = await processLocationData(playbookAnalysis.locationMap);
        // console.log("Processed Data:", processedData);
        if (processedData.length === 0) {
          throw new Error("No data returned from processLocationData.");
        }
        const { categories, formattedData } = countAndFormatClauses(processedData);
        // console.log(processedData);
        const { PartitionKey, FileId } = processedData[0];

        formatClauseData(categories);
        dispatch(setCategorywiseClauses(categories));
        dispatch(setRedlineHeader(formattedData));
        dispatch(
          setActiveData({
            playbookId: PartitionKey,
            fileId: FileId,
          })
        );

        // Update analysis payload with current user
        dispatch(
          setAnalysisPayload({
            userId,
            playbookId: PartitionKey,
            fileId: FileId,
          })
        );

        dispatch(setAnalysisResults(categories));
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error?.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const countAndFormatClauses = (data: any) => {
    const categoryCounts = {
      risks: 0,
      missingClauses: 0,
      // favorable: 0,
    };

    const categories = data.reduce(
      (acc: any, item: any) => {
        if (item.category === "contradictory") {
          acc.riskClauses.push(item);
          categoryCounts.risks++;
        } else if (item.category === "missing") {
          acc.missingClauses.push(item);
          categoryCounts.missingClauses++;
        }
        // else if (item.category === "favourable") {
        //   acc.favClauses.push(item);
        //   categoryCounts.favorable++;
        // }
        return acc;
      },
      {
        riskClauses: [],
        missingClauses: [],
        // favClauses: [],
      }
    );

    const formattedData = [];
    if (categoryCounts.risks > 0) {
      formattedData.push({ color: "#F87171", label: `${categoryCounts.risks} Risks` });
    }
    if (categoryCounts.missingClauses > 0) {
      formattedData.push({ color: "#FB923C", label: `${categoryCounts.missingClauses} Missing Clauses` });
    }
    // if (categoryCounts.favorable > 0) {
    //   formattedData.push({ color: "#22C55E", label: `${categoryCounts.favorable} Favourable` });
    // }

    return { categories, formattedData };
  };

  const handleTabClick = (tab: "risks" | "missing") => {
    setActiveTab(tab);
  };

  const handleClauseAccept = (type: "risks" | "missing", clause: Clause, index: number) => {
    setOriginalIndexes((prev) => ({
      ...prev,
      [clause.key]: index,
    }));

    setClauseData((prev) => ({
      ...prev,
      [type]: prev[type].filter((_, i) => i !== index),
    }));

    setLastRemovedClause({
      clause,
      type,
      index,
      actionType: "Accepted",
    });
    setShowMessage({ clause, actionType: "Accepted" });
  };

  const handleClauseReject = (type: "risks" | "missing", clause: Clause, index: number) => {
    setOriginalIndexes((prev) => ({
      ...prev,
      [clause.key]: index,
    }));

    setClauseData((prev) => ({
      ...prev,
      [type]: prev[type].filter((_, i) => i !== index),
    }));

    setLastRemovedClause({
      clause,
      type,
      index,
      actionType: "Rejected",
    });
    setShowMessage({ clause, actionType: "Rejected" });
  };

  const handleUndo = async () => {
    if (!lastRemovedClause) return;

    const { clause, type, index } = lastRemovedClause;
    const originalIndex = originalIndexes[clause.key] ?? index;

    // Revert changes in the Word document
    try {
      // Revert strikethrough, color, and remove replacement text in the document
      await undoStrikeOutAndReplaceInDoc(clause.location, clause.updatedContext || "");

      // Remove any comments added for this clause
      await removeSidePanelComments();

      // Remove the green replacement text added during "Accept"
      if (clause.updatedContext && clause.updatedContext.trim().length > 0) {
        await removeTextWord(clause.updatedContext);
      }

      // Explicitly remove the "Missing Clauses" heading if the type is "missing"
      if (type === "missing") {
        // Define the heading text to remove (e.g., "Missing Clauses" or the cleaned clause key)
        const headingToRemove = clause.key.replace(/^Clause-[\d.]+/, "").trim() || "Missing Clauses";
        await removeTextWord(headingToRemove); // Use removeTextWord to delete the heading
      }

      // Restore the clause in the UI
      setClauseData((prev) => {
        const newClauses = [...prev[type]];
        newClauses.splice(originalIndex, 0, clause);
        return {
          ...prev,
          [type]: newClauses,
        };
      });

      setLastRemovedClause(null);
      setShowMessage(null);
    } catch (error) {
      console.error("Error during undo operation:", error);
      setErrorMessage("Failed to undo the changes in the document. Please try again.");
    }
  };

  const cleanClauseText = (text: string) => {
    return text.replace(/ \(\d+\)$/, "");
  };

  if (isLoading) {
    return (
      <div style={{ display: "flex", height: "100vh" }}>
        <SidebarMain />
        <div style={{ flexGrow: 1, padding: "20px" }}>
          <Loader />
        </div>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div style={{ display: "flex", height: "100vh" }}>
        <SidebarMain />
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div
            style={{
              backgroundColor: "#FFE4E6",
              padding: "8px",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              gap: "12px",
              maxWidth: "307px",
              height: "115px",
              marginLeft: "8px",
            }}
          >
            {/* Warning Icon */}
            {/* <div
              style={{
                color: "#F87171",
                fontSize: "20px",
              }}
            >
              
            </div> */}

            {/* Error Message Content */}
            <div style={{ textAlign: "left", color: "#1F2937" }}>
              <h2
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#1F2937",
                }}
              >
                ⚠️ Something went wrong
              </h2>
              {/* <p
                style={{
                  fontSize: "14px",
                  margin: "0 0 4px 0",
                  color: "#4B5563",
                }}
              >
                {errorMessage}
              </p> */}
              {errorMessage === "Low Token Balance!" ? (
                <span style={{ fontSize: "14px", margin: "0", color: "#646464" }}>
                  Something went wrong while processing your request Please try again after some time or contact us at
                  <a
                    href="mailto:info@legalgraph.ai"
                    style={{ color: "#3B82F6", textDecoration: "underline", paddingLeft: "2px" }}
                  >
                    info@legalgraph.ai
                  </a>
                </span>
              ) : (
                <p style={{ fontSize: "14px", margin: "0", color: "#646464" }}>
                  Something went wrong while processing your request Please try again after some time or contact us at
                  <a
                    href="mailto:info@legalgraph.ai"
                    style={{ color: "#3B82F6", textDecoration: "underline", paddingLeft: "2px", paddingRight: "2px" }}
                  >
                    info@legalgraph.ai
                  </a>
                  for assistance
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ display: "flex", height: "100vh", overflow: "hidden", scrollbarWidth: "none", msOverflowStyle: "none" }}
    >
      <SidebarMain />
      <div
        style={{
          flexGrow: 1,
          height: "100%",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* sticky area */}
        <div
          style={{
            position: "sticky",
            top: 0,
            background: "#fff",
            zIndex: 10,
            padding: "0.5rem",
          }}
        >
          {/* Risk color bar */}
          {/* <div
            style={{
              marginTop: "0.5rem",
              border: "1px solid #E7E7EC",
              borderRadius: "8px",
              padding: "0.5rem",
              height: "75px",
              marginBottom: "0.75rem",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", marginTop: "-5px", marginBottom: "5px" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  margin: "0", // Removes extra margin for better alignment
                }}
              >
                Contract acceptability
              </p>
              <img
                src={infoIcon}
                alt="infoicon"
                style={{
                  width: "14px",
                  height: "14px",
                  marginLeft: "5px", // Adds spacing between text and icon
                }}
              />
              <p
                style={{
                  color: "#0D0D2C",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  margin: "0",
                  marginLeft: "96px",
                }}
              >
                82%
              </p>
            </div>
            <div
              style={{
                display: "flex",
                height: "10px",
                borderRadius: "8px",
                overflow: "hidden",
                marginBottom: "0.5rem",
                position: "relative",
              }}
            >
              <div style={{ flex: 1, backgroundColor: "#F87171" }}></div>
              <div style={{ flex: 1, backgroundColor: "#FB923C" }}></div>
              <div style={{ flex: 2, backgroundColor: "#22C55E", position: "relative" }}>
                <img
                  src={indicatorIcon} // Replace with your actual image source
                  alt="indicator"
                  style={{
                    position: "absolute",
                    top: "-6px", // Adjust to position the icon on top
                    left: "50%", // Centers the icon horizontally
                    transform: "translateX(-50%)", // Ensure proper centering
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                }}
              >
                High risk
              </p>
              <p
                style={{
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  marginLeft: "170px",
                }}
              >
                Low risk
              </p>
            </div>
          </div> */}
          {/* Dynamically Render Risk Data on tabs, upto this area is sticky */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "70px",
              marginLeft: "5rem",
              marginBottom: "-1.5rem",
              marginTop: "0.75rem",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                marginBottom: "0.75rem",
                color: activeTab === "risks" ? "#0A0A22" : "#9292A6",
              }}
              onClick={() => handleTabClick("risks")}
            >
              Risks
            </p>
            <p
              style={{
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                marginBottom: "0.75rem",
                color: activeTab === "missing" ? "#0A0A22" : "#9292A6",
                cursor: "pointer",
              }}
              onClick={() => handleTabClick("missing")}
            >
              Missing
            </p>
            {/* <p
              style={{
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                marginBottom: "0.75rem",
                color: activeTab === "favourable" ? "#0A0A22" : "#9292A6",
              }}
              onClick={() => handleTabClick("favourable")}
            >
              Favourable
            </p> */}
          </div>
          {/* horizontal tab line */}
          <div style={{ display: "flex", width: "100%", height: "1px", marginTop: "1rem" }}>
            <div
              style={{
                width: "50%",
                backgroundColor: activeTab === "risks" ? "#4A88EE" : "#9292A6",
              }}
            />
            <div
              style={{
                width: "50%",
                backgroundColor: activeTab === "missing" ? "#4A88EE" : "#9292A6",
              }}
            />
            {/* <div
              style={{
                width: "33%",
                backgroundColor: activeTab === "favourable" ? "#4A88EE" : "#9292A6",
              }}
            /> */}
          </div>
        </div>
        <AnalysisClausebox
          clauses={clauseData[activeTab]}
          type={activeTab}
          onAccept={handleClauseAccept}
          onReject={handleClauseReject}
          isAutoRedline={isAutoRedline}
        />
        <div style={{ marginTop: "auto", marginLeft: "0px", marginRight: "20px" }}>
          {showMessage && (
            <>
              <ErrorPage
                clause={cleanClauseText(showMessage.clause.key)}
                actionType={showMessage.actionType}
                onUndo={handleUndo}
              />
            </>
          )}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysePage;
