/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@store/store";
import Footer from "../../components/Footer";
import SidebarMain from "../../components/SidebarMain";
import Dropdown from "../../../taskpane/components/Dropdown";
import {
  clearAnalysisData,
  setAnalysisPayload,
  setSelectedContractType,
  setSelectedPlaybookId,
} from "../../../store/analysisSlice";
import { getUserPlaybooks, fetchPlaybookTerms, switchPlaybook } from "../../../services/playbook-service";
import { MessageBar, MessageBarType } from "@fluentui/react";
import editIcon from "../../../../assets/edit_pencil.png";
import saveIcon from "../../../../assets/save.png";

const ManagePlaybook: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const [Playbooks, setPlaybooks] = useState([]);
  const [filteredPlaybooks, setFilteredPlaybooks] = useState([]);
  const [activePlaybookId, setActivePlaybookId] = useState<string>("");
  const [playbookDetails, setPlaybookDetails] = useState<any>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  // const [selectedPlaybookId, setSelectedPlaybookId] = useState<string>("");
  // const [selectedContractType, setSelectedContractType] = useState<string>("master_service");
  const [loadingPlaybooks, setLoadingPlaybooks] = useState<boolean>(true); // Loading state for playbooks dropdown
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false); // Loading state for playbook details
  const analysisPayload_fromredux = useSelector((state: RootState) => state.analysis.analysisPayload);
  const selectedContractType = useSelector((state: RootState) => state.analysis.selectedContractType);
  const selectedPlaybookId = useSelector((state: RootState) => state.analysis.selectedPlaybookId);

  const fetchPlaybookDetailsById = async (userId: string, playbookId: string) => {
    // console.log("konsi playbook ho : ", playbookId);
    if (!userId || !playbookId) return;
    setLoadingDetails(true); // Set loading true before fetching details
    try {
      const response = await fetchPlaybookTerms(userId, playbookId);
      setPlaybookDetails(response);
    } catch (error) {
      console.error("Error fetching playbook details:", error);
      setError("Failed to fetch playbook details");
    } finally {
      setLoadingDetails(false);
    }
  };

  const storedUserId = localStorage.getItem("activeUserId");
  useEffect(() => {
    const loadPlaybooks = async (userId: string) => {
      setLoadingPlaybooks(true);
      try {
        const playbooksData = await getUserPlaybooks(userId);
        if (playbooksData) {
          setPlaybooks(playbooksData);
          const initialFiltered = playbooksData.filter((playbook) => playbook.contractType === selectedContractType);
          setFilteredPlaybooks(initialFiltered);
          const activePlaybook = initialFiltered.find((playbook) => playbook.active);
          if (activePlaybook) {
            setActivePlaybookId(activePlaybook.playbookId);
            setSelectedPlaybookId(activePlaybook.playbookId);
            await fetchPlaybookDetailsById(userId, activePlaybook.playbookId);
          } else if (initialFiltered.length > 0) {
            const firstPlaybook = initialFiltered[0];
            const switchResponse = await switchPlaybook(userId, firstPlaybook.playbookId);
            if (switchResponse) {
              setActivePlaybookId(firstPlaybook.playbookId);
              setSelectedPlaybookId(firstPlaybook.playbookId);
              dispatch(
                setAnalysisPayload({
                  ...analysisPayload_fromredux,
                  playbookId: firstPlaybook.playbookId,
                })
              );
              await fetchPlaybookDetailsById(userId, firstPlaybook.playbookId);
            }
          }
        }
      } catch (error) {
        console.error("Error loading playbooks:", error);
        setError("Failed to load playbooks");
      } finally {
        setLoadingPlaybooks(false);
      }
    };

    if (storedUserId) {
      loadPlaybooks(storedUserId);
    }
  }, [storedUserId, selectedContractType]);

  const sortedPlaybooks = [...filteredPlaybooks].sort((a, b) => {
    if (a.active) return -1;
    if (b.active) return 1;
    return a.playbookName.localeCompare(b.playbookName);
  });

  const contractTypes = [
    { label: "Master Service Agreement", value: "master_service" },
    { label: "Non-Disclosure Agreement", value: "non_disclosure" },
    { label: "Others", value: "unknown" },
  ];

  const handleContractTypeChange = async (value: string) => {
    dispatch(setSelectedContractType(value));
    setLoadingPlaybooks(true);
    const filtered = Playbooks.filter((playbook) => playbook.contractType === value);
    setFilteredPlaybooks(filtered);
    setSelectedPlaybookId("");
    setPlaybookDetails([]);

    if (filtered.length > 0) {
      const firstPlaybook = filtered[0];
      if (!firstPlaybook.active) {
        try {
          const switchResponse = await switchPlaybook(storedUserId, firstPlaybook.playbookId);
          if (switchResponse) {
            setActivePlaybookId(firstPlaybook.playbookId);
            setSelectedPlaybookId(firstPlaybook.playbookId);
            dispatch(
              setAnalysisPayload({
                ...analysisPayload_fromredux,
                playbookId: firstPlaybook.playbookId,
              })
            );
          }
        } catch (error) {
          setError("Failed to switch playbook");
        }
      } else {
        setActivePlaybookId(firstPlaybook.playbookId);
        setSelectedPlaybookId(firstPlaybook.playbookId);
      }
      await fetchPlaybookDetailsById(storedUserId, firstPlaybook.playbookId);
    }
    setLoadingPlaybooks(false);
  };

  const handlePlaybookSelect = async (value: string) => {
    try {
      dispatch(setSelectedPlaybookId(value));
      await fetchPlaybookDetailsById(storedUserId, value);
      if (activePlaybookId && value !== activePlaybookId) {
        const switchResponse = await switchPlaybook(storedUserId, value);
        if (switchResponse) {
          setActivePlaybookId(value);
          dispatch(
            setAnalysisPayload({
              ...analysisPayload_fromredux,
              playbookId: value,
            })
          );
        } else {
          setError("Failed to switch playbook.");
        }
      }
    } catch (error) {
      setError("An unexpected error occurred while switching playbooks. Please try again later.");
    }
  };

  const activePlaybook = sortedPlaybooks.find(
    (playbook) => playbook.playbookId === (selectedPlaybookId || activePlaybookId)
  );
  const cleanClauseText = (text: string) => {
    return text.replace(/ \(\d+\)$/, "");
  };

  const handleStartAnalysis = () => {
    setShowDialog(true);
  };

  const triggerRiskAnalysis = async () => {
    try {
      setShowDialog(false);
      dispatch(clearAnalysisData());
      if (Office.context.document) {
        await Word.run(async (context) => {
          const docBody = context.document.body;
          docBody.load("text");
          await context.sync();
          if (docBody.text.length > 0) {
            navigate("/analyze");
          } else {
            setError("Please open a contract in Word to start analysis.");
          }
        });
      } else {
        setError("No Word document is open. Please open a contract in Word to start analysis.");
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  const contentRef = useRef([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [clampedIndexes, setClampedIndexes] = useState([]);
  useEffect(() => {
    const clamped = playbookDetails.map((_, index) => {
      const ref = contentRef.current[index];
      return ref && ref.scrollHeight > 60;
    });
    setClampedIndexes(clamped);
  }, [playbookDetails]);
  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedContent, setEditedContent] = useState<string>("");
  const [editedHeader, setEditedHeader] = useState<string>("");

  const handleEditClick = (index: number, content: string, header: string) => {
    setEditingIndex(index);
    setEditedContent(content);
    setEditedHeader(header);
  };

  const handleSaveClick = async (index: number) => {
    try {
      const updatedPlaybooks = [...playbookDetails];
      updatedPlaybooks[index].standardAnswer = editedContent;
      setPlaybookDetails(updatedPlaybooks);
      setEditingIndex(null);
      setEditedContent("");
      setEditedHeader("");
    } catch (error) {
      console.error("Error saving playbook:", error);
      setError("Failed to save changes");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        position: "relative",
      }}
    >
      <SidebarMain />
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <div
          style={{
            padding: "1rem",
            overflowY: "auto",
            height: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "500",
              marginBottom: "20px",
              color: "#13123E",
              textAlign: "left",
              lineHeight: "1.4",
            }}
          >
            Manage Playbooks
          </h3>

          <div style={{ marginBottom: "16px" }}>
            <label
              htmlFor="contractType"
              style={{ display: "block", marginBottom: "8px", fontSize: "14px", fontWeight: "500", color: "#08081A" }}
            >
              Contract Type
            </label>
            <Dropdown options={contractTypes} onSelect={handleContractTypeChange} value={selectedContractType} />
            <p style={{ fontSize: "10px", fontWeight: "400", textAlign: "left", color: "#61607E", marginTop: "5px" }}>
              Select the contract type to view the available playbook
            </p>
          </div>

          <div style={{ marginBottom: "16px" }}>
            <label
              htmlFor="playbook"
              style={{ display: "block", marginBottom: "8px", fontSize: "14px", fontWeight: "500", color: "#08081A" }}
            >
              Choose Playbook
            </label>
            {loadingPlaybooks ? (
              <p style={{ fontSize: "14px", color: "#61607E" }}>Loading...</p>
            ) : (
              <Dropdown
                options={sortedPlaybooks.map((playbook) => ({
                  label: playbook.playbookName,
                  value: playbook.playbookId,
                }))}
                onSelect={handlePlaybookSelect}
                value={selectedPlaybookId}
              />
            )}
            <p style={{ fontSize: "10px", fontWeight: "400", textAlign: "left", color: "#61607E", marginTop: "5px" }}>
              Select the playbook to use for contract analysis
            </p>
          </div>

          <div style={{ marginBottom: "16px" }}>
            <label
              htmlFor="playbook"
              style={{ display: "block", marginBottom: "2px", fontSize: "14px", fontWeight: "500", color: "#08081A" }}
            >
              {activePlaybook?.playbookName}
            </label>
            <p style={{ fontSize: "10px", fontWeight: "400", textAlign: "left", color: "#61607E" }}>
              Below are the standard rules of this playbook.
            </p>
          </div>

          <div
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              paddingRight: "10px",
              scrollbarWidth: "thin",
              msOverflowStyle: "auto",
            }}
          >
            {loadingDetails ? (
              <p style={{ fontSize: "14px", color: "#61607E", textAlign: "center" }}>Loading...</p>
            ) : playbookDetails.length > 0 ? (
              playbookDetails.map((playbook, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "0.60rem",
                    marginBottom: "15px",
                    background: "#ffffff",
                    width: "270px",
                    maxHeight: "auto",
                    overflow: expandedIndex === index ? "auto" : "hidden",
                    position: "relative",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {editingIndex === index ? (
                      <input
                        value={editedHeader}
                        onChange={(e) => setEditedHeader(e.target.value)}
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#424165",
                          marginBottom: "4px",
                          padding: "4px",
                          width: "60%",
                          border: "none",
                          outline: "none",
                          background: "transparent",
                        }}
                      />
                    ) : (
                      <h4 style={{ fontSize: "12px", fontWeight: "600", color: "#424165", marginBottom: "4px" }}>
                        {cleanClauseText(playbook.key)}
                      </h4>
                    )}
                    <div
                      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                      onClick={() =>
                        editingIndex === index
                          ? handleSaveClick(index)
                          : handleEditClick(index, playbook.standardAnswer, playbook.key)
                      }
                    >
                      <span style={{ fontSize: "12px", fontWeight: "400", color: "#9292A6", marginRight: "0px" }}>
                        {editingIndex === index ? "Save" : "Edit"}
                      </span>
                      <img
                        src={editingIndex === index ? saveIcon : editIcon}
                        alt={editingIndex === index ? "Save" : "Edit"}
                        style={{ width: "16px", height: "16px", objectFit: "contain" }}
                      />
                    </div>
                  </div>
                  <div style={{ position: "relative" }}>
                    {editingIndex === index ? (
                      <textarea
                        value={editedContent}
                        onChange={(e) => setEditedContent(e.target.value)}
                        style={{
                          width: "100%",
                          minHeight: "85px",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "1.4",
                          color: "#61607E",
                          padding: "8px",
                          resize: "vertical",
                          border: "none",
                          outline: "none",
                          background: "transparent",
                        }}
                      />
                    ) : (
                      <p
                        ref={(el) => (contentRef.current[index] = el)}
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "1.4",
                          color: "#61607E",
                          maxHeight: expandedIndex === index ? "none" : "85px",
                          margin: "0",
                          overflow: "hidden",
                          mask:
                            expandedIndex === index
                              ? "none"
                              : "linear-gradient(to bottom, black 85%, transparent 100%)",
                          WebkitMask:
                            expandedIndex === index
                              ? "none"
                              : "linear-gradient(to bottom, black 85%, transparent 100%)",
                        }}
                      >
                        {playbook.standardAnswer}
                      </p>
                    )}
                    {playbook.standardAnswer.length > 60 && (
                      <span
                        onClick={() => handleExpand(index)}
                        style={{
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "#4A88EE",
                          cursor: "pointer",
                          position: "absolute",
                          bottom: "-8px",
                          right: "0",
                          background: "#f9f9f9",
                          paddingLeft: "4px",
                        }}
                      >
                        {expandedIndex === index ? "See Less" : "See More"}
                      </span>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p style={{ fontSize: "14px", color: "#61607E", textAlign: "center" }}>No playbook details available.</p>
            )}
          </div>

          {error && (
            <MessageBar messageBarType={MessageBarType.warning} isMultiline={false} dismissButtonAriaLabel="Close">
              {error}
            </MessageBar>
          )}

          <div style={{ display: "flex", flexDirection: "column", marginTop: "24px", gap: "8px" }}>
            <button
              style={{
                width: "270px",
                padding: "10px 20px",
                fontSize: "14px",
                fontWeight: "500",
                backgroundColor: "#4A88EE",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              // onClick={() => triggerRiskAnalysis()}
              onClick={handleStartAnalysis}
            >
              Start Analysis
            </button>
            <style>
              {`
                    @keyframes fadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
              }
        
               @keyframes slideIn {
                from {
                  transform: translateY(-20px);
                   opacity: 0;
                }
                to {
                   transform: translateY(0);
                    opacity: 1;
                  }
                }
      `}
            </style>
            {showDialog && (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  animation: "fadeIn 0.3s ease-in-out",
                  width: "100%",
                  height: "100%",
                  zIndex: 1000,
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "12px",
                    padding: "24px",
                    maxWidth: "400px",
                    width: "100%",
                    margin: "0 16px",
                    animation: "slideIn 0.3s ease-in-out",
                    zIndex: 1001,
                  }}
                >
                  <div style={{ marginBottom: "24px" }}>
                    <div
                      style={{
                        width: "48px",
                        height: "48px",
                        backgroundColor: "#FEF2F2",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "0 auto 16px auto",
                      }}
                    >
                      <span style={{ fontSize: "24px" }}>⚠️</span>
                    </div>
                    <h3
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#111827",
                        marginBottom: "8px",
                        textAlign: "center",
                      }}
                    >
                      You are about to restart the risk analysis
                    </h3>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#6B7280",
                        textAlign: "center",
                      }}
                    >
                      This action will remove all existing redlines. Do you wish to continue?
                    </p>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                    <button
                      onClick={triggerRiskAnalysis}
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "#3E51EE",
                        padding: "10px 20px",
                        borderRadius: "8px",
                        borderColor: "#3E51EE",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      Restart Analysis
                    </button>
                    <button
                      onClick={() => setShowDialog(false)}
                      style={{
                        width: "100%",
                        backgroundColor: "#F3F4F6",
                        color: "#374151",
                        padding: "10px 20px",
                        borderRadius: "8px",
                        border: "none",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            <button
              style={{
                width: "270px",
                padding: "10px 20px",
                fontSize: "14px",
                fontWeight: "500",
                backgroundColor: "#ffff",
                color: "#4A88EE",
                border: "1px solid #4A88EE",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/upload")}
            >
              Upload New Playbook
            </button>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "auto" }}>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePlaybook;
