/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import SidebarMain from "../components/SidebarMain";
import backIcon from "../../../assets/back.png";

const ReasoningPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clause } = location.state || {};
  const [key, setKey] = useState(clause?.key || "");
  const [analysis, setAnalysis] = useState(clause?.analysis || "");
  const [page, setPage] = useState(clause?.page || null);

  const handleBack = () => {
    // Navigate back with state to indicate we're coming from reasoning
    navigate("/analyze", { state: { from: "reasoning" } });
  };

  const cleanClauseText = (text: string) => {
    return text.replace(/ \(\d+\)$/, "");
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <SidebarMain />
      <div
        style={{
          flexGrow: 1,
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          backgroundColor: "white",
        }}
      >
        {/* Back Button */}
        <div style={{ display: "flex", alignItems: "center", gap: "8px", cursor: "pointer" }} onClick={handleBack}>
          <img src={backIcon} style={{ width: "24px", height: "24px" }} />
          <span style={{ fontSize: "14px", fontStyle: "normal", fontWeight: "400", color: "#424165" }}>
            {cleanClauseText(key)}
          </span>
        </div>

        {/* Reason for risk */}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "0px",
            }}
          >
            <h3 style={{ fontSize: "12px", fontStyle: "normal", fontWeight: "400", color: "#08081A" }}>AI reasoning</h3>
          </div>
          <div
            style={{
              width: "100%",
              padding: "12px",
              borderRadius: "8px",
              backgroundColor: "#F5F5F5",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              color: "#424165",
              overflow: "auto",
              whiteSpace: "pre-wrap", // Ensures text wraps as needed
              wordWrap: "break-word", // Prevents overflow
              height: "auto", // Height adjusts based on content
            }}
          >
            {analysis.length > 0 ? analysis : "No reasoning detected."}
          </div>
        </div>

        {/* source*/}
        {/* <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "0px",
            }}
          >
            <h3 style={{ fontSize: "12px", fontStyle: "normal", fontWeight: "400", color: "#08081A" }}>
              Source (Page Number)
            </h3>
          </div>
          <div
            style={{
              width: "100%",
              padding: "12px",
              borderRadius: "8px",
              backgroundColor: "#F5F5F5",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              color: "#424165",
              overflow: "auto",
              whiteSpace: "pre-wrap", // Ensures text wraps as needed
              wordWrap: "break-word", // Prevents overflow
              height: "auto", // Height adjusts based on content
            }}
          >
            {page !== null ? `This discrepancy was identified on Page ${page}.` : "Page number not available."}
          </div>
        </div> */}

        {/* <Footer /> */}
        <div style={{ marginTop: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ReasoningPage;
