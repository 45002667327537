/* global Office */

import * as React from "react";
import App from "./App";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { OfficeWrapper } from "./components/OfficeWrapper";

/* global document */

Office.onReady((info) => {
  if (info.host === Office.HostType.Word) {
    const rootElement = document.getElementById("root");
    const root = createRoot(rootElement);
    root.render(
      <StrictMode>
        <OfficeWrapper>
          <App />
        </OfficeWrapper>
      </StrictMode>
    );
  }
});
