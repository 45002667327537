import React, { useState, useEffect, useRef } from "react";
import securityIcon from "../../../assets/security.png";
import triangleInfo from "../../../assets/traingleinfo.png";

const Footer: React.FC = () => {
  const [showSecurityTooltip, setShowSecurityTooltip] = useState(false);
  const [showDisclaimerTooltip, setShowDisclaimerTooltip] = useState(false);

  const securityRef = useRef<HTMLDivElement>(null);
  const disclaimerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (securityRef.current && !securityRef.current.contains(event.target as Node)) {
        setShowSecurityTooltip(false);
      }
      if (disclaimerRef.current && !disclaimerRef.current.contains(event.target as Node)) {
        setShowDisclaimerTooltip(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#ffff",
        border: "1px solid #E7E7EC",
        borderRadius: "12px",
        textAlign: "center",
        width: "229px",
        height: "34px",
        marginBottom: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "10px",
        paddingLeft: "4px",
        paddingRight: "4px",
        position: "relative",
      }}
    >
      {/* Top Section with Icons and Text */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
          marginBottom: "12px",
        }}
      >
        <div
          ref={securityRef}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
          }}
          onClick={() => setShowSecurityTooltip(!showSecurityTooltip)}
        >
          <span
            style={{
              fontSize: "10px",
              fontWeight: "500",
              color: "#424165",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <img src={securityIcon} alt="security" style={{ width: "15px", height: "15px" }} />
            Secure Connection
          </span>
        </div>
        <div
          ref={disclaimerRef}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
          }}
          onClick={() => setShowDisclaimerTooltip(!showDisclaimerTooltip)}
        >
          <span
            style={{
              fontSize: "10px",
              fontWeight: "500",
              color: "#424165",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              textDecoration: "underline",
            }}
          >
            <img src={triangleInfo} alt="disclaimer" style={{ width: "15px", height: "15px" }} />
            Disclaimer
          </span>
        </div>
      </div>

      {/* Security Tooltip */}
      {showSecurityTooltip && (
        <div
          style={{
            position: "absolute",
            bottom: "100%",
            left: "40%",
            transform: "translateX(-50%)",
            backgroundColor: "#fff",
            border: "1px solid #E7E7EC",
            borderRadius: "4px",
            padding: "8px",
            fontSize: "10px",
            fontWeight: "500",
            color: "#424165",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            width: "200px",
            whiteSpace: "normal",
            textAlign: "left",
          }}
        >
          256-bit AES Encryption. Your files are stored securely in LegalGraph. Your data is not shared with any third
          party, and you can delete your data anytime.
        </div>
      )}

      {/* Disclaimer Tooltip */}
      {showDisclaimerTooltip && (
        <div
          style={{
            position: "absolute",
            bottom: "100%",
            left: "60%",
            transform: "translateX(-50%)",
            backgroundColor: "#fff",
            border: "1px solid #E7E7EC",
            borderRadius: "4px",
            padding: "8px",
            fontSize: "10px",
            fontWeight: "500",
            color: "#424165",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            width: "200px",
            whiteSpace: "normal",
            textAlign: "left",
          }}
        >
          The content provided in this analysis is AI-generated and is intended for informational purposes. It does not
          constitute legal advice. <br />
          <p style={{ textDecoration: "underline", cursor: "pointer", color: "#4A88EE" }}>View Privacy Policy.</p>
        </div>
      )}
    </div>
  );
};

export default Footer;
