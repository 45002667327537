import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "../models/UserData";
import { UserGeneratedPlaybook } from "../models/Playbook";
// import { persistor } from "./store";

// Define the state structure
interface UserState {
  userDetails: UserInfo; // Using the imported UserInfo interface
  userPlaybook: Partial<UserGeneratedPlaybook>; // Using Partial to handle optional properties
}

// Initial state
const initialState: UserState = {
  userDetails: {
    email: null,
    username: null,
    userId: null,
    token: null,
  },
  userPlaybook: {
    playbookId: null,
    userId: null,
    active: false,
    contractType: null,
    playbookName: null,
    creationDataTime: null,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserInfo>) => {
      state.userDetails = action.payload;
    },
    logoutUser: (state) => {
      state.userDetails = {
        email: null,
        username: null,
        userId: null,
        token: null,
      };
      state.userPlaybook = {
        playbookId: null,
        userId: null,
        active: false,
        contractType: null,
        playbookName: null,
        creationDataTime: null,
      };
      localStorage.clear();
      // persistor.purge();
    },
    autoLogoutUser: (state) => {
      // Only set the token to null, keeping other user details intact
      state.userDetails.token = null;
      localStorage.removeItem("authToken"); // Remove the token from local storage
    },
    setUsergeneratedPlaybook: (state, action: PayloadAction<Partial<UserGeneratedPlaybook>>) => {
      state.userPlaybook = action.payload;
    },
  },
});

export const { setUser, logoutUser, autoLogoutUser, setUsergeneratedPlaybook } = userSlice.actions;

export default userSlice.reducer;
