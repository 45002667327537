import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import backIcon from "../../../../assets/back.png";

const PasswordReset: React.FC = () => {
  const navigate = useNavigate();
  const [Password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    navigate("/home"); //after suucessful submision directed to home/landing page
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "100vh",
        padding: "0rem",
        backgroundColor: "#ffffff",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "400px",
          padding: "1.15rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: "12px",
          }}
          onClick={() => navigate("/verifyotp")}
        >
          <img src={backIcon} alt="Back" style={{ width: "24px", height: "24px", marginRight: "10px" }} />
          <span style={{ fontSize: "14px", fontWeight: "400", fontStyle: "normal", color: "#13123E" }}>Back</span>
        </div>
        {/* Header */}
        <div style={{ textAlign: "left", marginBottom: "8px" }}>
          <p style={{ fontSize: "16px", fontWeight: "700", color: "#13123E", marginBottom: "0px" }}>Set New Password</p>
          <p style={{ fontSize: "12px", fontWeight: "400", color: "#13123E" }}>Must be at least 8 characters</p>
        </div>

        {/* Error message */}
        {error && <p style={{ color: "red", fontSize: "12px", marginBottom: "10px" }}>{error}</p>}

        {/* Form */}
        <form onSubmit={handleSubmit}>
          <label
            htmlFor="password"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              color: "#08081A",
            }}
          >
            Password
          </label>
          <input
            id="password"
            name="password"
            type="text"
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              width: "100%",
              height: "35px",
              padding: "10px",
              marginBottom: "24px",
              fontSize: "12px",
              borderRadius: "4px",
              border: "1px solid #ddd",
              color: "#9292A6",
            }}
          />

          <label
            htmlFor="password"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              color: "#08081A",
            }}
          >
            Confirm Password
          </label>
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="text"
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              width: "100%",
              height: "35px",
              padding: "10px",
              marginBottom: "24px",
              fontSize: "12px",
              borderRadius: "4px",
              border: "1px solid #ddd",
              color: "#9292A6",
            }}
          />

          {/* Submit Button */}
          <button
            type="submit"
            style={{
              display: "block",
              width: "100%",
              height: "40px",
              backgroundColor: "#4A88EE",
              color: "#ffffff",
              borderRadius: "8px",
              fontSize: "12px",
              fontWeight: 500,
              textAlign: "center",
              border: "none",
              cursor: "pointer",
            }}
          >
            Save
          </button>
        </form>
      </div>
      <div style={{ marginLeft: "1rem" }}>
        <Footer />
      </div>
    </div>
  );
};

export default PasswordReset;
