import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import backIcon from "../../../../assets/back.png";
import { persistor } from "../../../store/store";
import { HandleSignup } from "../../../services/signup_backend";
import { jwtDecode } from "jwt-decode";
import toast from "react-hot-toast";

interface SignupProps {
  onSignup: () => void;
}

interface SignupInputs {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
}

interface ValidationErrors {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

interface CustomJwtPayload {
  email: string;
  username: string;
  userId: string;
}

const SignupPage: React.FC<SignupProps> = ({ onSignup }) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});

  const [userInputs, setUserInputs] = useState<SignupInputs>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
  });

  useEffect(() => {
    setButtonDisabled(
      !(userInputs.email && userInputs.password && userInputs.phone && userInputs.firstName && userInputs.lastName)
    );
  }, [userInputs]);

  const validateField = (name: string, value: string) => {
    if (!value.trim()) {
      return `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    }
    return "";
  };

  const handleBlur = (fieldName: string) => {
    setTouched((prev) => ({ ...prev, [fieldName]: true }));
    const error = validateField(fieldName, userInputs[fieldName as keyof SignupInputs]);
    setValidationErrors((prev) => ({
      ...prev,
      [fieldName]: error,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserInputs((prev) => ({ ...prev, [name]: value }));
    if (touched[name]) {
      const error = validateField(name, value);
      setValidationErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  async function sendRequest() {
    setIsLoading(true);
    try {
      const jobRole = "";
      const company = "";

      const fullName = `${userInputs.firstName} ${userInputs.lastName}`;
      const response = await HandleSignup(
        userInputs.email,
        userInputs.password,
        fullName,
        userInputs.phone,
        jobRole,
        company
      );
      const { token } = response.data;
      const decodedToken = jwtDecode<CustomJwtPayload>(token);

      if (!decodedToken) {
        throw new Error("Failed to decode token");
      }

      const { email, username, userId } = decodedToken;
      localStorage.clear();
      (state) => {
        state = undefined;
        localStorage.clear();
        persistor.purge();
      };
      localStorage.setItem("authToken", token);
      localStorage.setItem("activeUserId", userId);
      localStorage.setItem("activeMail", email);
      localStorage.setItem("activeName", username);
      onSignup();
      toast.success("Signup successful!");
      navigate("/");
    } catch (error) {
      console.error("Signup Error:", error);
      setError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Touch all fields to show validation errors
    const fields = ["email", "password", "firstName", "lastName", "phone"];
    const newTouched = fields.reduce((acc, field) => ({ ...acc, [field]: true }), {});
    setTouched(newTouched);

    // Validate all fields
    const errors: ValidationErrors = {};
    fields.forEach((field) => {
      const error = validateField(field, userInputs[field as keyof SignupInputs]);
      if (error) errors[field as keyof ValidationErrors] = error;
    });

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    setError("");
    await sendRequest();
  };

  const inputStyle = (fieldName: string) => ({
    width: "100%",
    height: "35px",
    padding: "10px",
    marginBottom: touched[fieldName] && validationErrors[fieldName] ? "2px" : "10px",
    fontSize: "12px",
    borderRadius: "4px",
    border: `1px solid ${touched[fieldName] && validationErrors[fieldName] ? "#ff4d4f" : "#ddd"}`,
    color: "#000000", // Changed from #9292A6 to #000000 (black)
  });

  const errorMessageStyle = {
    color: "#ff4d4f",
    fontSize: "12px",
    marginBottom: "8px",
    marginTop: "-2px",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "100vh",
        padding: "0rem",
        backgroundColor: "#ffffff",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "400px",
          padding: "1.5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: "5px",
          }}
          onClick={() => navigate("/")}
        >
          <img src={backIcon} alt="Back" style={{ width: "24px", height: "24px", marginRight: "10px" }} />
          <span style={{ fontSize: "14px", fontWeight: "400", fontStyle: "normal", color: "#13123E" }}>Back</span>
        </div>
        <div style={{ textAlign: "left", marginTop: "7px" }}>
          <p style={{ fontSize: "16px", fontWeight: "700", color: "#13123E" }}>Complete registration to access</p>
        </div>

        {error && <p style={{ color: "#ff4d4f", fontSize: "12px", marginBottom: "10px" }}>{error}</p>}

        <form onSubmit={handleSubmit}>
          <label
            htmlFor="first-name"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              color: "#08081A",
            }}
          >
            First Name
          </label>
          <input
            id="first-name"
            name="firstName"
            type="text"
            value={userInputs.firstName}
            onChange={handleChange}
            onBlur={() => handleBlur("firstName")}
            style={inputStyle("firstName")}
          />
          {touched.firstName && validationErrors.firstName && (
            <p style={errorMessageStyle}>{validationErrors.firstName}</p>
          )}

          <label
            htmlFor="last-name"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              color: "#08081A",
            }}
          >
            Last Name
          </label>
          <input
            id="last-name"
            name="lastName"
            type="text"
            value={userInputs.lastName}
            onChange={handleChange}
            onBlur={() => handleBlur("lastName")}
            style={inputStyle("lastName")}
          />
          {touched.lastName && validationErrors.lastName && (
            <p style={errorMessageStyle}>{validationErrors.lastName}</p>
          )}

          <label
            htmlFor="email"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              color: "#08081A",
            }}
          >
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            value={userInputs.email}
            onChange={handleChange}
            onBlur={() => handleBlur("email")}
            style={inputStyle("email")}
          />
          {touched.email && validationErrors.email && <p style={errorMessageStyle}>{validationErrors.email}</p>}

          <label
            htmlFor="password"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              color: "#08081A",
            }}
          >
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            value={userInputs.password}
            onChange={handleChange}
            onBlur={() => handleBlur("password")}
            style={inputStyle("password")}
          />
          {touched.password && validationErrors.password && (
            <p style={errorMessageStyle}>{validationErrors.password}</p>
          )}

          <label
            htmlFor="phone"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              color: "#08081A",
            }}
          >
            Phone Number
          </label>
          <input
            id="phone"
            name="phone"
            type="text"
            value={userInputs.phone}
            onChange={handleChange}
            onBlur={() => handleBlur("phone")}
            style={inputStyle("phone")}
          />
          {touched.phone && validationErrors.phone && <p style={errorMessageStyle}>{validationErrors.phone}</p>}

          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
            <div style={{ display: "flex" }}>
              <input id="remember-me" type="checkbox" style={{ marginRight: "5px" }} />
              <label
                htmlFor="remember-me"
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  cursor: "pointer",
                  color: "#4A88EE",
                  textDecoration: "underline",
                }}
              >
                I accept the Terms of Use
              </label>
            </div>
          </div>

          <button
            type="submit"
            style={{
              display: "block",
              width: "100%",
              height: "40px",
              backgroundColor: "#4A88EE",
              color: "#ffffff",
              borderRadius: "4px",
              fontSize: "12px",
              fontWeight: 500,
              textAlign: "center",
              border: "none",
              cursor: "pointer",
              marginBottom: "20px",
              opacity: buttonDisabled || isLoading ? 0.7 : 1,
            }}
            disabled={buttonDisabled || isLoading}
          >
            {isLoading ? "Loading..." : "Sign Up"}
          </button>
        </form>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "3rem" }}>
        <Footer />
      </div>
    </div>
  );
};

export default SignupPage;
