/* eslint-disable */
import axios from "axios";
import * as dotenv from "dotenv";
dotenv.config();

// Initializing variables
const apiUrl = process.env.LOGIN_API_URL;

async function HandleSignup(email: string, password: string, name: string, phone: string, jobRole: string, company: string) {
  try {
    const payload = { 
      email,
      password,
      name,
      jobRole,
      company,
      phone
    };

    // Making the user login API request
    const response = await axios.post(`${apiUrl}/auth/signup`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export { HandleSignup };
