import React from "react";
import { Link } from "react-router-dom";

export const BackButton: React.FC = () => (
  <Link
    to="/"
    style={{ textDecoration: "none", fontSize: "1.5rem" }}
    className="inline-block bg-gray-500 text-primary py-2 rounded-lg hover:bg-gray-600"
  >
    ⬅️
  </Link>
);
