import React from "react";
import { useState, useEffect } from "react";
import { UserPlaybook } from "../../models/Playbook";
import { getUserPlaybooks, switchPlaybook } from "../../services/playbook-service";
import { RefreshCw } from "lucide-react";
import { BackButton } from "../components/BackButton";

const SettingsPage: React.FC = () => {
  const [playbooks, setPlaybooks] = useState<UserPlaybook[]>([]);
  const [selectedPlaybookId, setSelectedPlaybookId] = useState<string>("");
  const [activePlaybook, setActivePlaybook] = useState<UserPlaybook | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  useEffect(() => {
    loadPlaybooks();
  }, []);

  const loadPlaybooks = async () => {
    try {
      const playbooksData = await getUserPlaybooks("1fd7ceb2-5ebd-4c77-8777-3ba87c695faf");
      console.log(`PlaybooksData: ${playbooksData}`);
      setPlaybooks(playbooksData);

      const active = playbooksData.find((p) => p.active);
      if (active) {
        setActivePlaybook(active);
        setSelectedPlaybookId(active.id);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error loading playbooks:", error);
      setIsLoading(false);
    }
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    setIsRefreshing(true);
    await loadPlaybooks();
    setIsRefreshing(false);
  };

  const handleApply = async () => {
    if (!activePlaybook || selectedPlaybookId === activePlaybook.playbookId) {
      return;
    }

    setIsApplying(true);

    try {
      await switchPlaybook("1fd7ceb2-5ebd-4c77-8777-3ba87c695faf", selectedPlaybookId);

      await loadPlaybooks();
    } catch (error) {
      console.error("Error switching playbooks:", error);
    } finally {
      setIsApplying(false);
    }
  };

  if (isLoading) {
    return (
      <div style={{ height: "100vh" }} className="w-100 d-flex justify-content-center align-items-center">
        <img className="w-25" src="https://i.gifer.com/VAyR.gif" alt="" />
      </div>
    );
  }

  const sortedPlaybooks = [...playbooks].sort((a, b) => {
    if (a.active) return -1;
    if (b.active) return 1;
    return a.playbookName.localeCompare(b.playbookName);
  });

  return (
    <div className="p-4">
      <div className="mb-2">
        <BackButton />
        <h2>LegalGraph AI </h2>
      </div>
      <button
        onClick={handleRefresh}
        disabled={isRefreshing}
        className="p-1 hover:bg-gray-100 rounded-full transition-colors"
        title="Refresh playbooks"
      >
        <RefreshCw className={`w-2 h-2 text-gray-600 ${isRefreshing ? "animate-spin" : ""}`} />
      </button>
      <div className="mb-4" style={{ margin: "1rem 0rem 1rem 0rem" }}>
        <label htmlFor="playbook-select" className="block text-sm font-medium mb-2">
          Select Playbook
        </label>
        <div className="relative">
          <select
            id="playbook-select"
            value={selectedPlaybookId}
            onChange={(e) => setSelectedPlaybookId(e.target.value)}
            className="w-100 p-2 border rounded-md bg-white h-24 text-ellipsis overflow-hidden"
          >
            {sortedPlaybooks.map((playbook) => (
              <option
                key={playbook.playbookId}
                value={playbook.playbookId}
                className="py-2 overflow-hidden text-ellipsis whitespace-nowrap"
                style={{ maxWidth: "100%", margin: "1rem" }}
              >
                {playbook.playbookName} {playbook.active ? "(Active)" : ""}
              </option>
            ))}
          </select>
        </div>
      </div>

      <button
        onClick={handleApply}
        type="button"
        disabled={
          !selectedPlaybookId || (activePlaybook && selectedPlaybookId === activePlaybook.playbookId) || isApplying
        }
        className="w-full btn btn-primary p-2 rounded-md hover:bg-blue-600 
                   disabled:bg-gray-300 disabled:cursor-not-allowed
                   flex items-center justify-center"
      >
        {isApplying ? "Applying Changes..." : "Apply"}
      </button>
    </div>
  );
};

export default SettingsPage;
