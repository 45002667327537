import { configureStore } from "@reduxjs/toolkit";
import analysisReducer from "./analysisSlice";
import userReducer from "./userDataSlice";
import fileReducer from "./fileSlice";
import toggleReducer from "./toggleSlice";
// import { persistStore, persistReducer } from "redux-persist";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Using localStorage by default

// Define the persist config
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
  serialize: true, // Ensure proper serialization
};

// Persist user reducer
const persistedUserReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
  reducer: {
    toggle: toggleReducer,
    files: fileReducer,
    analysis: analysisReducer,
    user: persistedUserReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store); // Create persistor

export { store, persistor };

// Define the root state type
export type RootState = ReturnType<typeof store.getState>;
