import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { contractFileInfo } from "../models/contractFileData";

// Define the initial state
interface FileState {
  fileData: contractFileInfo[];
}

const initialState: FileState = {
  fileData: [],
};

// Create the slice
const fileSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFileResponse(state, action: PayloadAction<contractFileInfo[]>) {
      state.fileData = action.payload; // Set the entire files array
    },
    clearFileResponse(state) {
      state.fileData = []; // Clear the files array by resetting to an empty array
    },
  },
});

export const { setFileResponse, clearFileResponse } = fileSlice.actions;
export default fileSlice.reducer;
