/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "@store/store";
import { setUser } from "../../store/userDataSlice";
import SidebarMain from "../components/SidebarMain";
import Footer from "../components/Footer";
import addContractIcon from "../../../assets/AddContract.png";
import profileIcon from "../../../assets/profile.png";
import MasterSettings from "../components/MasterSettings";

interface SettingsPageProps {
  onLogout: () => void;
}
const SettingsPage: React.FC<SettingsPageProps> = ({ onLogout }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<"contractSettings" | "profile">("contractSettings");
  const [isContractOpen, setIsContractOpen] = useState<boolean>(false);
  // const [isLoading, setIsLoading] = useState<boolean>(true);
  const userName = localStorage.getItem("activeName");
  const userMail = localStorage.getItem("activeMail");

  //accessing the p from redux store
  const userDetails_fromredux = useSelector((state: RootState) => state.user.userDetails);
  console.log("userdetails", userDetails_fromredux);

  useEffect(() => {
    const initializeUserData = async () => {
      try {
        if (!userDetails_fromredux?.userId) {
          const storedData = localStorage.getItem("persist:root");
          if (storedData) {
            const parsedRoot = JSON.parse(storedData);
            if (parsedRoot.user) {
              const parsedUser = JSON.parse(parsedRoot.user);
              if (parsedUser.userDetails) {
                dispatch(setUser(parsedUser.userDetails));
              }
            }
          }
        }
      } catch (error) {
        console.error("Error loading persisted data:", error);
      }
    };

    initializeUserData();
  }, [dispatch, userDetails_fromredux]);

  const handleLogout = () => {
    onLogout(); // Call the logout function passed from the App component
    navigate("/"); // Redirect to the login or landing page
  };

  useEffect(() => {
    const checkContractState = async () => {
      if (Office.context?.document) {
        try {
          await Word.run(async (context) => {
            const docBody = context.document.body;
            docBody.load("text"); // Load the document body text
            await context.sync();

            if (docBody.text.length > 0) {
              setIsContractOpen(true);
            } else {
              setIsContractOpen(false);
            }
          });
        } catch (error) {
          console.error("Error checking document state:", error);
          setIsContractOpen(false);
        }
      } else {
        setIsContractOpen(false); // Office context is not available
      }
    };
    checkContractState();
  }, []);

  const handleTabChange = (tab: "contractSettings" | "profile") => {
    setActiveTab(tab);
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <SidebarMain />
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start", // Align content to the top
          // justifyContent: "start",
          alignItems: "left",
          padding: "20px",
          // minHeight: "100vh",
        }}
      >
        {/* Tabs */}
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "20px",
            // width: "100%",
          }}
        >
          <button
            onClick={() => handleTabChange("contractSettings")}
            style={{
              border: activeTab === "contractSettings" ? "1px solid #4A88EE" : "none",
              borderRadius: "8px",
              background: "#E7E7EC",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "500",
              padding: "10px",
              cursor: "pointer",
              color: activeTab === "contractSettings" ? "#0D0D2C" : "#61607E",
            }}
          >
            Contract Settings
          </button>
          <button
            onClick={() => handleTabChange("profile")}
            style={{
              border: activeTab === "profile" ? "1px solid #4A88EE" : "none",
              borderRadius: "8px",
              background: "#E7E7EC",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "500",
              padding: "10px",
              cursor: "pointer",
              color: activeTab === "profile" ? "#0D0D2C" : "#61607E",
            }}
          >
            Profile
          </button>
        </div>
        {/* Tab Content */}
        {activeTab === "contractSettings" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // width: "100%",
              flexGrow: 1, // Allow it to take available space
            }}
          >
            {isContractOpen ? (
              <MasterSettings />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10rem",
                }}
              >
                <img src={addContractIcon} alt="Loading" style={{ width: "5rem", marginBottom: "10px" }} />
                <p style={{ color: "#424165", fontSize: "14px", fontStyle: "normal" }}>
                  Open a contract to customize settings and enable configuration options.
                </p>
              </div>
            )}
          </div>
        )}
        {activeTab === "profile" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
              height: "160px",
              padding: "10px",
              border: "1px solid #E7E7EC",
              borderRadius: "8px",
              // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Profile Icon with Circular Background */}
            <div
              style={{
                width: "45px",
                height: "45px",
                backgroundColor: "#F5F5F5",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2px",
              }}
            >
              <img src={profileIcon} alt="Profile" style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
            </div>

            {/* User Details */}
            <div style={{ marginBottom: "1px", fontSize: "14px", fontWeight: "500px", color: "#13123E" }}>
              {userName}
              {/* {userDetails_fromredux.username} */}
            </div>
            <div style={{ marginBottom: "10px", fontSize: "12px", fontWeight: "500px", color: "#61607E" }}>
              {userMail}
              {/* {userDetails_fromredux.email } */}
            </div>
            {/* <div style={{ marginBottom: "20px", fontSize: "12px", fontWeight: "500px", color: "#61607E" }}>
              +91 1234567890
            </div> */}

            {/* Log Out Button */}
            <button
              onClick={handleLogout}
              style={{
                // marginTop: "20px",
                padding: "3px 5px",
                fontSize: "12px",
                backgroundColor: "#fff",
                color: "#EF4444",
                border: "1px solid #EF4444",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Log Out
            </button>
          </div>
        )}
        {/* Footer*/}
        <div style={{ marginTop: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
