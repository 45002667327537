import React, { useState } from "react";
import axios from "axios";
import { Layout } from "../components/Layout";
import { BackButton } from "../components/BackButton";
import { getDocumentMetadata } from "../../services/document-service";
import * as dotenv from "dotenv";
dotenv.config();

// Initializing variables
const apiUrl = process.env.API_URL;

const GeneratePlaybook: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setCsvFile(event.target.files[0]);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!csvFile) {
      setUploadStatus("Please add a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("playbookName", (await getDocumentMetadata()) + Math.floor(Math.random() * 1000));
    formData.append("csv", csvFile);
    formData.append("userId", "1fd7ceb2-5ebd-4c77-8777-3ba87c695faf");

    try {
      setIsLoading(true);
      const response = await axios.post(apiUrl + "/api/v1/add-in/generate-playbook", formData);

      console.log(response.data.success);

      if (response.data.success) {
        setUploadStatus("Playbook generated successfully!");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("got the error");
      }
    } catch (error) {
      setUploadStatus(`Error: ${error.message}`);
    }
  };

  if (isLoading) {
    return (
      <div style={{ height: "100vh", textAlign: "center" }} className="w-100 d-flex align-items-center">
        <div>
          <img className="w-25 mb-5" src="https://i.gifer.com/VAyR.gif" alt="Loading" />
          <h2>Generating playbook from provided CSV. Please wait!</h2>
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <div className="container">
        <BackButton />
        <h2>Generate Playbook</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="csv">Upload CSV File:</label>
            <input type="file" id="csv" accept=".csv" onChange={handleFileChange} required />
          </div>
          <button type="submit">Generate Playbook</button>
        </form>
        {uploadStatus && <p>{uploadStatus}</p>}
      </div>
    </Layout>
  );
};

export default GeneratePlaybook;
