import React from "react";
import SidebarMain from "../../components/SidebarMain";
import Footer from "../../components/Footer";
import cloudIcon from "../../../../assets/cloud.png";

const ContactUs: React.FC = () => {
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <SidebarMain />
      <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", width: "100%", padding: "1rem" }}>
        <h3
          style={{
            fontSize: "14px",
            marginBottom: "20px",
            color: "#333",
            textAlign: "left",
            lineHeight: "1.4",
            fontWeight: "600",
          }}
        >
          Contact Us
        </h3>

        <form>
          {/* title */}
          <label
            htmlFor="title"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              color: "#08081A",
            }}
          >
            Title
          </label>
          <input
            id="title"
            name="title"
            type="text"
            // value={title}
            style={{
              width: "100%",
              height: "35px",
              padding: "10px",
              marginBottom: "10px",
              fontSize: "12px",
              borderRadius: "4px",
              border: "1px solid #ddd",
              color: "#9292A6",
            }}
          />

          {/* Description */}
          <label
            htmlFor="description"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              color: "#08081A",
            }}
          >
            Description
          </label>
          <input
            id="description"
            name="description"
            type="text"
            // value={description}
            style={{
              width: "100%",
              height: "90px",
              padding: "10px",
              marginBottom: "10px",
              fontSize: "12px",
              borderRadius: "4px",
              border: "1px solid #ddd",
              color: "#9292A6",
            }}
          />

          {/* image */}
          <label
            htmlFor="image"
            style={{
              display: "block",
              fontSize: "12px",
              fontWeight: "500",
              marginBottom: "5px",
              color: "#08081A",
            }}
          >
            Upload Image
          </label>
          {/* Upload Box */}
          <div
            style={{
              width: "263px",
              height: "160px",
              borderRadius: "8px",
              padding: "10px",
              textAlign: "center",
              backgroundColor: "#F9FAFB",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Cloud Icon Box */}
            <div
              style={{
                width: "247px",
                height: "43px",
                border: "1px dashed #C3CBD8",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                cursor: "pointer",
              }}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#e6e6e6")}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#FFFFFF")}
            >
              {/* Hidden File Input */}
              <input
                type="file"
                id="csv"
                accept=".csv, .docx, .pdf"
                required
                style={{
                  position: "absolute",
                  width: "247px",
                  height: "43px",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
              <img
                src={cloudIcon}
                alt="Upload Icon"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </div>

            {/* Upload Message */}
            <p
              style={{
                fontSize: "12px",
                color: "#8A94A6",
                textAlign: "center",
                maxWidth: "247px",
                marginLeft: "0px",
                paddingTop: "8px",
                marginBottom: "0px",
              }}
            >
              Please upload an image file in PNG, JPG, or <br /> JPEG format only. Maximum file size{" "}
              <strong>10 MB</strong>
            </p>
          </div>
        </form>

        <div style={{ marginTop: "auto", marginLeft: "-25px" }}>
          {/* Submit Button */}
          <button
            type="submit"
            style={{
              // display: "block",
              width: "90%",
              height: "40px",
              backgroundColor: "#4A88EE",
              color: "#ffffff",
              borderRadius: "8px",
              fontSize: "12px",
              fontWeight: 500,
              textAlign: "center",
              border: "none",
              cursor: "pointer",
              marginBottom: "10px",
              marginLeft: "30px",
              marginRight: "30px",
            }}
          >
            Send
          </button>
          {/* Footer */}
          <div
            style={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "25px",
            }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
